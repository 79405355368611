import axios from '../../utils/axios';

export interface IContact {
    id: number;
    uid: string;
    name: string;
    email: string;
    mobileNumber: string;
};

export interface ITag {
    name: string;
    id: number;
    memberDTO: {
        uid: string;
        name: string;
    };
    createdAt: string;
};

export const getContacts = async (businessId: string) => {
    interface GetContactsResponse {
        count: number;
        list: IContact[];
        tags: ITag[];
    }
    return axios.post<GetContactsResponse>("contact/getAll", {
        uid: businessId,
        limit: 1000,
        page: 0,
    });
}

export const getTags = async (businessUid: string, page: number = 0, search: string|null = null) => {
    return axios.post("tag/getAll", {
        businessUid: businessUid,
        limit: 10,
        page,
        search
    });
}

export const createTag = async (businessUid: string, memberId: string, name: string) => {
    return axios.post<ITag>("tag/createTag", {
        businessUid,
        memberId,
        name
    });
}