import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import EditorCaption from '../editorCaption';
import { useElmentEditor } from '../hooks';
import { IEditorProps } from '../types';
import BodyInput from '../bodyInput';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../addVariable.module.scss';
import AddVariable from '../addVariable';
import classes from '../integration/shopify.module.scss';
import ReactSelect, { components } from 'react-select';
import { SelectVariable } from '../setVariable';
import { IStorageVariable } from '../../../../entity/Variable';
import { useSelector } from 'react-redux';
import { getTicketFields } from '../../../../../services/ticketService';
import { useAppSelector } from '../../../../hooks';
import { getAllTeam} from '../../../../../services/contactMemberService';


interface FormData {
    ticketFlow: {
        data: {
            subject: string,
            type: string,
            description: string,
            priority: string,
            team?: string
        }
    },
    storageVariable: IStorageVariable<'OBJECT'>;
};

const CustomOption = (props: any) => {
    const { data, label, children } = props;
    const content = label?.length > 35 ? (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${data.value}`}>{data.originalLabel}</Tooltip>}
        >
            <span>{children}</span>
        </OverlayTrigger>
    ) : (
        <span>{children}</span>
    );

    return (
        <components.Option {...props}>
            {content}
        </components.Option>
    );
};

function CreateTicketEditor(props: IEditorProps) {
    const channelUid = useAppSelector(state => state.meta.channelUid);
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [options, setOptions] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [priorityOptions, setPriorityOptions] = useState([ {label: "Urgent", value: "Urgent"}, {label:"High", value:"High"},  {label:"Medium", value: "Medium"}, {label:"Low", value: "Low"}]);
    const [formData, setFormData] = useState<FormData>({
        ticketFlow: {
           data: {
              subject: '',
              type: '',
              description: '',
              priority: "Low"
           }
        },
        storageVariable: {
            name: '',
            type: "OBJECT"
        }

    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'create_Ticket',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        ticketFlow: yup.object().shape({
            data: yup.object().shape({
                subject: yup.string().required('Subject is required'),
                type: yup.string().required('Type is required'),
                description: yup.string().required('Description is required'),
            }),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                const getTickets = async (channelUid: any) => {
                    const fields = await getTicketFields(channelUid);
                    const type = fields?.data?.find((field: any) => field?.name === 'type');
                    const options = type?.options?.filter((option: any) => option.deleted === false);   
                    const typeOptions = options?.map((option: any) => ({label: option?.label, value: option?.label}));
                    setOptions(typeOptions);
                }

                const getTeams = async (businessId: any) => {
                    const payload = {
                        businessUid: businessId,
                        page:0,
                        limit: 1000
                    }
                    const teams = await getAllTeam(payload);
                    const options = teams?.data?.map((team: any) => {
                        const trimmedName = team?.name?.trim();
                        return {
                          label: trimmedName.length <= 40 ? trimmedName : trimmedName.substring(0, 40) + "...",
                          originalLabel: trimmedName,
                          value: team?.uid,
                        }; 
                    });
                    setTeamOptions(options);                      
                }
                useEffect(() => {
                    getTickets(channelUid);
                    getTeams(businessUid);
                },[])

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Create Ticket' icon={<FontAwesomeIcon icon={faTicket} className={styles.ticketing} />} />
                        <Offcanvas.Body>


                            <Form.Group className='mb-3'>
                                <Form.Label>Subject <span className='required'></span></Form.Label>
                                <Form.Control
                                    name='ticketFlow.data.subject'
                                    value={values?.ticketFlow?.data?.subject}
                                    onChange={handleChange}
                                    placeholder="Enter Subject"
                                />
                                <ErrorMessage
                                    name="ticketFlow.data.subject"
                                    component="div"
                                    className='invalid-feedback d-block'
                                />
                                <div className={classes.addVariable}>
                                    <AddVariable product static fieldName='ticketFlow.data.subject' fieldValue={values?.ticketFlow?.data?.subject} limit={1024} />
                                </div>
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Priority </Form.Label>
                                <ReactSelect
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 475 })
                                    }}
                                    options={priorityOptions}
                                    className="w-100"
                                    placeholder="Select Priority"
                                    onChange={(priority: any) => {
                                        setFieldValue('ticketFlow.data.priority',priority.value);
                                    }}
                                    value={priorityOptions?.find((option: any) => (option?.label === values?.ticketFlow?.data?.priority))}
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Type <span className='required'></span></Form.Label>
                                <ReactSelect
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 475 })
                                    }}
                                    options={options}
                                    className="w-100"
                                    placeholder="Select Type"
                                    onChange={(type: any) => {
                                        setFieldValue('ticketFlow.data.type',type.value);
                                    }}
                                    value={options?.find((option: any) => (option?.label === values?.ticketFlow?.data?.type))}
                                />
                                <ErrorMessage
                                    name="ticketFlow.data.type"
                                    component="div"
                                    className='invalid-feedback d-block'
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <BodyInput
                                    label="Description"
                                    name='ticketFlow.data.description'
                                    value={values?.ticketFlow?.data?.description}
                                    isInvalid={(touched?.ticketFlow?.data?.description && errors?.ticketFlow?.data?.description) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched?.ticketFlow?.data?.description && !errors?.ticketFlow?.data?.description}
                                    error={errors?.ticketFlow?.data?.description}
                                    required={true}
                                    limit={1024}
                                    placeholder="Enter Description"
                                />
                                <ErrorMessage
                                    name="ticketFlow.data.description"
                                    component="div"
                                    className='invalid-feedback d-block mt-0'
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Team </Form.Label>
                                <ReactSelect
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 400, position: "relative" })
                                    }}
                                    options={teamOptions}
                                    className="w-100"
                                    placeholder="Select Team"
                                    onChange={(type: any) => {
                                        setFieldValue('ticketFlow.data.team',type.value);
                                    }}
                                    value={teamOptions?.find((option: any) => (option?.value === values?.ticketFlow?.data?.team))}
                                    maxMenuHeight={120}
                                    components={{ Option: CustomOption }} 
                                />
                            </Form.Group>

                            <Form.Group className='mb-17'>
                                <Form.Label>
                                    Variable
                                </Form.Label>
                                <SelectVariable
                                    placeholder='Create or Select variable'
                                    name='storageVariable'
                                    value={values?.storageVariable?.name ? String(values?.storageVariable?.name) : ''}
                                    onChange={handleChange}
                                    type='OBJECT'
                                    varType="appointment_confirmation"
                                />
                            </Form.Group>

                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default CreateTicketEditor;