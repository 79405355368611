import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { textIcon } from '../../../../icons';
import EditorCaption from '../editorCaption';
import { useElmentEditor } from '../hooks';
import { IEditorProps } from '../types';
import BodyInput from '../bodyInput';
import { uuidv4 } from '../../../../utils/uuid';
import OptionsInput, { IOption } from '../optionsInput';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../addVariable.module.scss';


interface FormData {
    bodyText: string,
    flowData: {
        type: string | null,
        screenName: string | null,
        buttonName: string,
        flowAction: string
    },
    ticketFlow: {
        createTicket: string,
        updateTicket: string
    },
    targetId: string

};

function TicketingEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        bodyText: 'Welcome to BizMagnets Ticketing!',
        flowData: {
            type: null,
            screenName: '',
            buttonName: '',
            flowAction: 'navigate'
        },
        ticketFlow: {
            createTicket: '',
            updateTicket: '',
        },
        targetId: ''

    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'ticketIt',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        bodyText: yup.string().required('Message Text is required'),
        flowData: yup.object().shape({
        buttonName: yup.string().required('Button Name is required'),
        screenName: yup.string().required('Screen Name is required')
    })

    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                const handleSubmission = () => {
                    setFieldValue('flowData.flowAction', "navigate");
                    setFieldValue('flowData.type', null);
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Tickets' icon={ <FontAwesomeIcon icon={faTicket} className={styles.ticketing} />} />
                        <Offcanvas.Body>

                            <Form.Group className='mb-3'>
                                <BodyInput
                                    label="Message Text"
                                    name='bodyText'
                                    value={values.bodyText}
                                    isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.bodyText && !errors.bodyText}
                                    error={errors.bodyText}
                                    required={true}
                                    limit={1024}
                                    placeholder="Enter Message Text"
                                />
                                <ErrorMessage
                                    name="bodyText"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Button Name <span className='required'></span></Form.Label>
                                <Form.Control
                                    name='flowData.buttonName'
                                    value={values.flowData.buttonName}
                                    onChange={handleChange}
                                    placeholder="Enter Button Name"
                                />
                                 <ErrorMessage
                                    name="flowData.buttonName"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Screen Name <span className='required'></span></Form.Label>
                                <Form.Control
                                    name='flowData.screenName'
                                    value={values.flowData.screenName ?? ''}
                                    onChange={handleChange}
                                    placeholder="Enter Screen Name"
                                />
                                 <ErrorMessage
                                    name="flowData.screenName"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>
                            {/* 
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    label="Reply Text"
                                    name='ticketFlow.askElementData.bodyText'
                                    value={values.ticketFlow.askElementData.bodyText}
                                    isInvalid={(touched.ticketFlow?.askElementData?.bodyText && errors.ticketFlow?.askElementData?.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.ticketFlow?.askElementData?.bodyText && !errors.ticketFlow?.askElementData?.bodyText}
                                    error={errors.ticketFlow?.askElementData?.bodyText}
                                    required={true}
                                    limit={1024}
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Reply Button Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    onChange={handleChange}
                                    value={values.ticketFlow.askElementData.options[0].buttonMessage.title}
                                    name="ticketFlow.askElementData.options[0].buttonMessage.title"
                                />
                            </Form.Group> */}


                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSubmission}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default TicketingEditor;