import React from 'react';
import './common.scss';
import { Link } from 'react-router-dom';
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import warning from '../assets/img/upgrade.svg';
import { auth } from "../Firebase/firebase";
import { resetState } from '../redux/actions/action';
import { useDispatch } from 'react-redux';
import { clearWindowCache } from './commonfns';
import bizLogo from '../assets/img/biz_orange_black.svg';
import { useSelector } from 'react-redux';
import { socket } from './socketconfig';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const SubscribePage = () => {
  const dispatch = useDispatch();
  const business = useSelector((state: any) => state.cartreducer.business);
  const subscribeSocket = socket;
  const navigate = useNavigate();

  return (
    <div className='sub'>
      <img
        src={bizLogo}
        className="main-logo  desktop-logo mt-1"
        alt="logo" width={150} height={45}
      />
      <Link to='/login' className='logOut' onClick={() => {
        dispatch(resetState());
        clearWindowCache();
        auth.signOut();
        localStorage.clear();
        if (subscribeSocket)
          subscribeSocket.disconnect();
      }}> <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-3 fa-lg ml-1" />Logout</Link>
      <div className='subscribePage'>
        <h6 className='text-warning text-center h3'>Do More With BizMagnets!</h6>
        <img
          src={warning}
          className="warningImg"
          alt="logo"
        />
        <br />
        <p className='text-dark h6 text-center'>Your current plan has expired.</p>
        <p className='text-center text-muted medium'>Unlock the full potential of WhatsApp integration! Subscribe now to activate your exclusive AI-powered features and elevate your customer engagement to new heights.</p>
        {business?.role?.name !== 'MEMBER' && <Button className='signUpBtn' onClick={() => {
          navigate('/subscription?plan=subscribe');
        }}>Upgrade Now</Button>}
      </div>
    </div>
  );
};

export default SubscribePage;
