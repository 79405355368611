import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Button, Col, Container, Row } from "reactstrap";
import txtIcon from "../../assets/img/text.png";
import docIcon from "../../assets/img/doc.png";
import pdfIcon from "../../assets/img/pdf.png";
import { MdArrowBack } from "react-icons/md";
import { toast } from "../../common/alert";

interface FolderPageProps {
    folder: {
        name: string;
        files: File[];
    };
}

const MAX_FILES = 5;
const MAX_FILE_SIZE_MB = 10;

const FolderDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { folder } = (location.state as FolderPageProps) || {};
    const [files, setFiles] = useState<File[]>(folder?.files || []);

    const handleFileDrop = (acceptedFiles: File[]) => {
        if (files.length + acceptedFiles.length > MAX_FILES) {
            toast('error', `You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles: File[] = [];
        acceptedFiles.forEach((file: File) => {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                toast('error', `Please upload files that are ${MAX_FILE_SIZE_MB}MB or smaller.`);
            } else {
                validFiles.push(file);
            }
        });

        setFiles([...files, ...validFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileDrop,
        accept: {
            "text/plain": [".txt"],
            "application/msword": [".doc", ".docx"],
            "application/pdf": [".pdf"],
        },
    });

    const getFileIcon = (file: File) => {
        const extension = file.name.split(".").pop();
        console.log("extension", extension);
        switch (extension) {
            case "txt":
                return txtIcon;
            case "doc":
            case "docx":
                return docIcon;
            case "pdf":
                return pdfIcon;
            default:
                return null;
        }
    };

    return (
        <Container>
            <Row className="align-items-center">
                <Col xs="auto">
                    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <MdArrowBack style={{ fontSize: "25px" }} />
                    </div>
                </Col>
                <Col>
                    <h5 className="pt-3">
                        Knowledge Folder: {folder?.name}
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end mr-2">
                    <Button className="sendButton">
                        Train
                    </Button>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <div
                        style={{
                            border: "1px dashed #000",
                            padding: "20px",
                            borderRadius: "5px",
                            textAlign: "center",
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <p>Drag & drop files here, or click to upload more files</p>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                {files?.map((file, index) => (
                    <Col key={index} md={2} className="text-center">
                        <div>
                            <img
                                src={getFileIcon(file) || ""}
                                alt={file.name}
                                style={{ width: "50px", height: "50px" }}
                            />
                            <p style={{ marginTop: "10px", wordWrap: "break-word" }}>
                                {file.name}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FolderDetails;
