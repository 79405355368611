import axios from "../utils/axios";
import { showAlert, toast } from "../common/alert";
import Swal from "sweetalert2";
import { FaTheRedYeti } from "react-icons/fa";

export const paymentGetAll = async (
  paymentPayload: any,
  component?: string
) => {
  try {
    const response = await axios.post(
      "payment/getPaymentDetails",
      paymentPayload
    );
    return response.data;
  } catch (error: any) {
    //showAlert('error',error)
  }
};
export const getTotalCredits = async (businessId: any) => {
  try {
    const response = await axios.get(`payment/${businessId}/getTotalCredits`);
    return response.data;
  } catch (error: any) {
    toast("error", error);
  }
};

export const createPaymentStatus = async (paymentPayload: any) => {
  try {
    const response = await axios.post(
      `payment/zoho/createPaymentIntent`,
      paymentPayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.response.data.errorDesc);
  }
};

export const retrievePaymentIntent = async (retrivePayload: any) => {
  try {
    const response = await axios.post(
      `payment/zoho/retrievePaymentIntent`,
      retrivePayload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error);
  }
};

export const cancelTransactionPending = async (paymentId: any) => {
  try {
    const response = await axios.delete(`payment/${paymentId}/delete`);
    return response.data;
  } catch (error: any) {
    toast("error", error);
  }
};
