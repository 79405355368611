import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";

interface KnowledgeFolder {
    name: string;
    files: File[];
}

const RAG = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [folders, setFolders] = useState<KnowledgeFolder[]>([]);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const validationSchema = Yup.object({
        knowledgeName: Yup.string().required("Knowledge name is required"),
    });

    const formik = useFormik({
        initialValues: {
            knowledgeName: "",
        },
        validationSchema,
        onSubmit: (values: any) => {
            handleAddFolder(values?.knowledgeName);
            setIsSubmitted(true);
        },
        validateOnBlur: true,
    });

    useEffect(() => {
        if (showModal) {
            formik.resetForm();
            setIsSubmitted(false);
        }
    }, [showModal]);

    const handleAddFolder = (name: string) => {
        setFolders([...folders, { name: name, files: [] }]);
        setShowModal(false);
    };

    const navigate = useNavigate();

    const handleFolderClick = (folder: KnowledgeFolder) => {
        navigate(`/RAG/${folder.name}`, { state: { folder } });
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h4 className="pt-4 pb-3">Knowledge Base</h4>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs="auto" className="d-flex align-items-center">
                    <div
                        onClick={() => setShowModal(true)}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                            textAlign: "center",
                            width: "150px",
                            height: "150px",
                            marginRight: "1rem",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                width: "85px",
                                height: "80px",
                                color: "#337ab7",
                                textAlign: "center",
                                lineHeight: "80px",
                                borderRadius: "5px",
                                fontSize: "64px",
                                backgroundColor: "#fff",
                                border: "1px solid",
                            }}
                        >
                            +
                        </span>
                        <h6 style={{ marginTop: "14px" }}>Add Knowledge</h6>
                    </div>
                </Col>
                {folders.map((folder, index) => (
                    <Col key={index} md={2} className={`text-center ${index === 0 ? "ml-4" : ""}`}>
                        <div
                            style={{
                                cursor: "pointer",
                                width: "150px",
                                height: "150px",
                            }}
                            onClick={() => handleFolderClick(folder)}
                        >
                            <FontAwesomeIcon
                                icon={faFolder}
                                style={{ fontSize: "87px", color: "#f3ce3e" }}
                            />
                            <h6 style={{ marginTop: "7px" }}>{folder.name}</h6>
                        </div>
                    </Col>
                ))}
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Knowledge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Knowledge Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter knowledge name"
                                name="knowledgeName"
                                value={formik.values.knowledgeName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.knowledgeName && !!formik.errors.knowledgeName}
                                isValid={formik.touched.knowledgeName && !formik.errors.knowledgeName}
                            />
                            {formik.touched.knowledgeName && formik.errors.knowledgeName ? (
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    {typeof formik.errors.knowledgeName === "string" && formik.errors.knowledgeName}
                                </div>
                            ) : null}
                        </Form.Group>
                        <div className="text-center mt-3">
                            <Button type="submit" variant="primary">
                                Create
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default RAG;
