import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, FormControl } from 'react-bootstrap';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { BsTrash } from 'react-icons/bs';
import * as yup from 'yup';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import styles from './switchCondition.module.scss';
import { uuidv4 } from '../../../utils/uuid';
import EditorCaption from './editorCaption';
import { switchConditionIcon } from '../../../icons';
import { MdAdd } from 'react-icons/md';
import { Label } from 'reactstrap';

interface FormData {
    switchOperation: {
        input: string;
        list: any[];
        defaultTargetId: string;
    }
};

interface IOption {
    id: string;
    title: string;
    order: number;
}

interface OptionsInputProps {
    options: IOption[];
    placeholder?: string;
    addLabel?: string;
    isInvalid: boolean;
    isValid: boolean;
    error: any; // Type: string | string[] | FormikErrors<IOption>[] | undefined
    touched: any;
}

const OptionsInput: React.FC<OptionsInputProps> = ({ options, addLabel, ...otherProps }) => {
    const formik = useFormikContext();
    const handleTitleChange = (index: number, text: string) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions[index] = { ...newOptions[index], title: text };
        formik.setFieldValue('switchOperation.list', newOptions);
    };

    const handleDelete = (index: number) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.splice(index, 1);
        formik.setFieldValue('switchOperation.list', newOptions);
    };

    const handleAddMatch = () => {
        const id = uuidv4();
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.push({ id, title: '', order: newOptions.length + 1 });
        formik.setFieldValue('switchOperation.list', newOptions);
    };

    return (
        <>
            {options.map((question, index) => {
                const error = (otherProps.error && otherProps.error[index] && otherProps.error[index].title) ? otherProps.error[index].title : null;
                const isTouched = otherProps.touched;
                return (
                    <div className={styles.optionBox} key={question.id}>
                        <div className={styles.questionRow}>
                            <div className={styles.deleteButton}>
                                <Button
                                    disabled={index === 0 && options.length <= 1}
                                    onClick={() => handleDelete(index)}
                                    size='sm'
                                    className='deleteSmallButton'
                                >
                                    <BsTrash />
                                </Button>
                            </div>
                            <div className={styles.matchText}>
                                <FormControl
                                    name={`switchOperation.list[${index}].title`}
                                    placeholder='Match text'
                                    value={question.title}
                                    isInvalid={error && isTouched}
                                    isValid={!error && isTouched}
                                    onChange={(e: any) => handleTitleChange(index, e.target.value)}
                                />
                                {error && isTouched ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {error}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            })}
            <Button onClick={handleAddMatch} size='sm' className='addButton'><MdAdd /></Button>
            {(otherProps.touched && otherProps.error && typeof otherProps.error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {otherProps.error}
                </div>
            ) : null}
        </>
    );
};

const SwitchConditionEditor: React.FC<IEditorProps> = props => {
    const [formData, setFormData] = useState<FormData>({
        switchOperation: {
            input: '',
            list: [
                { id: uuidv4(), title: '', order: 1 }
            ],
            defaultTargetId: uuidv4()
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'switchCondition',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const listItemSchema = yup.object().shape({
        id: yup.string().required(),
        title: yup.string().required('Match Text is required')
    });
    const schema = yup.object().shape({
        switchOperation: yup.object().shape({
            input: yup.string().required('Input is a required'),
            list: yup.array().of(listItemSchema).min(1, 'At least item is required'),
            defaultTargetId: yup.string()
        }),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Offcanvas.Body>
                            <EditorCaption onHide={props.onClose} caption='Switch' icon={<img style={{ width: 20 }} alt='' src={switchConditionIcon} />} />
                            <div className='mb-3'>
                                <Label>Input<span className='required'></span></Label>
                                <FormControl
                                    name='switchOperation.input'
                                    as='input'
                                    value={values.switchOperation.input}
                                    isInvalid={(touched.switchOperation?.input && errors.switchOperation?.input) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.switchOperation?.input && !errors.switchOperation?.input}
                                    placeholder="Enter the input text here"
                                />
                                {(touched.switchOperation?.input && errors.switchOperation?.input) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.switchOperation?.input}
                                    </div>
                                ) : null}
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Label>Match</Form.Label>
                                <OptionsInput
                                    options={values.switchOperation.list}
                                    error={errors.switchOperation?.list}
                                    isInvalid={(touched.switchOperation?.list && errors.switchOperation?.list) ? true : false}
                                    isValid={(touched.switchOperation?.list && !errors.switchOperation?.list) ? true : false}
                                    touched={touched.switchOperation?.list ? true : false}
                                />
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SwitchConditionEditor;