import React, { useState, useEffect, useRef } from "react";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import { Row, Col, Button, Input } from "reactstrap";
import { getAllTeam, getTeam } from "../../services/contactMemberService";
import { customStyles } from "../../common/react-select-style";
import "./ticketing.scss";
import {
  getTicketFields,
  patchTicketPropertiesUpdate,
} from "../../services/ticketService";
import TooltipReference from "../../common/tooltip";
import moment from "moment";

interface Option {
  label: string;
  value: string | number;
}

interface TicketField {
  id: number;
  name: string;
  label: string;
  position: number;
  type: string;
  isDefault: boolean;
  options: Option[];
  value: string | number;
  dropDown: boolean;
}

interface TicketFieldResponse {
  ticketField: TicketField;
  value: string | number;
}

interface TicketUpdateProps {
  selectedTicket: {
    uid: string;
    fields: TicketFieldResponse[];
    resolutionTimeDue?: string;
    firstResponseTimeDue?: string;
  };
  setLoadTicket: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  setIsMemberSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isMemberSelected: boolean;
  sendPressed: boolean;
  setSelectedTicket: React.Dispatch<React.SetStateAction<any>>;
  setCallStatusCount: React.Dispatch<React.SetStateAction<boolean>>;
}

const TicketUpdate: React.FC<TicketUpdateProps> = (props) => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );


  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const animatedComponents = makeAnimated();

  const [teams, setTeams] = useState<Option[]>([]);
  const [teamsMap, setTeamsMap] = useState<{ [key: string]: string }>({});
  const [members, setMembers] = useState<Option[]>([]);
  const [membersMap, setMembersMap] = useState<{ [key: string]: string }>({});
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [allDefaultTicketFields, setAllDefaultTicketFields] = useState<
    TicketField[]
  >([]);
  const [ticketFields, setTicketFields] = useState<any[]>([]);
  const formRef = useRef<null | HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [allAddOnsFields, setAllAddOnFields] = useState<TicketField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userProfile = useSelector((state: any) => state.cartreducer.business);
  
  const [resolutionTimeDue, setResolutionTimeDue] = useState<string | null>(
    props.selectedTicket?.resolutionTimeDue || ""
  );

  const [firstResponseTimeDue, setFirstResponseTimeDue] = useState<string | null>(
    props.selectedTicket?.firstResponseTimeDue || ""
  );

  useEffect(() => {
    const fields = props.selectedTicket?.fields || [];
    setTicketFields(fields);

    if (props.selectedTicket?.firstResponseTimeDue) {
      setFirstResponseTimeDue(props.selectedTicket?.firstResponseTimeDue);
    }
  
    if (props.selectedTicket?.resolutionTimeDue) {
      setResolutionTimeDue(props.selectedTicket?.resolutionTimeDue);
    }
  }, [props.selectedTicket]);

  const getTeams = async () => {
    try {
      const payload = {
        businessUid: businessId,
        page: 0,
        limit: 1000,
      };
      const team = await getAllTeam(payload, "ticketing");
      const teams = team.data.map((teamMember: any) => ({
        label: teamMember.name,
        value: teamMember.uid,
      }));

      setTeams(teams);

      const teamMap = team.data.reduce((acc: any, teamMember: any) => {
        acc[teamMember.uid.toLowerCase()] = teamMember.name;
        return acc;
      }, {});
      setTeamsMap(teamMap);
    } catch {
      setTeams([]);
    }
  };

  const getMembers = async (teamId: string) => {
    try {
      const team = await getTeam(teamId, "ticketing");
      const members = team.members.map((mem: any) => ({
        label: mem.name,
        value: mem.uid,
      }));

      setMembers(members);

      const memberMap = team.members.reduce((acc: any, member: any) => {
        acc[member.uid.toLowerCase()] = member.name;
        return acc;
      }, {});
      setMembersMap((prevMembersMap) => ({
        ...prevMembersMap,
        ...memberMap,
      }));
    } catch {
      setMembers([]);
    }
  };

  const handleTeamChange = async (option: Option, formikProps: any) => {
    const { field, form } = formikProps;
    setSelectedTeam(option.value.toString());
    await getMembers(option.value.toString());
    form.setFieldValue("member", "");
    form.setFieldValue(field.name, option?.value);
  };

  useEffect(() => {
    if (channelUid) {
      getTicketFields(channelUid).then((res) => {
        setAllDefaultTicketFields(
          res?.data?.filter((field: any) => field.isDefault)
        );
        setAllAddOnFields(res?.data?.filter((field: any) => !field.isDefault));
      });
      getTeams();
    }
  }, [channelUid]);

  useEffect(() => {
    const teamFields = props.selectedTicket?.fields?.filter(
      (field: any) => field.ticketField.name.toLowerCase() === "team"
    );
    if (teamFields?.length > 0 && typeof teamFields[0].value === "string") {
      getMembers(teamFields[0].value.toLowerCase());
    }
  }, [props.selectedTicket]);

  useEffect(() => {
    if (menuOpen && formRef.current) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    }
  }, [menuOpen]);

  useEffect(() => {
    if (props.sendPressed) {
      if (userProfile.role.type === "MEMBER") {
        if (!props.isMemberSelected) {
          const findMember = allDefaultTicketFields.find(
            (field: any) => field.name.toLowerCase() === "member"
          );
          const memberData = {
            ticketField: findMember,
            value: userProfile?.uid,
          };
          const defaultFields = allDefaultTicketFields.filter(
            (item) => item.type !== "member"
          );
          const updatedDefaultFields = defaultFields.map((field) => {
            const initialValue = initialValues[field.name];
            return {
              ticketField: { ...field },
              value: initialValue,
            };
          });

          const updatedAddOnsFields = allAddOnsFields.map((field) => {
            const value = initialAddOnsValues[field.name];
            return {
              ticketField: { ...field },
              value: value,
            };
          });

          const payload = {
            uid: props.selectedTicket.uid,
            fields: [
              ...updatedDefaultFields,
              memberData,
              ...updatedAddOnsFields,
            ],
          };

          patchTicketPropertiesUpdate(payload.uid, payload.fields, 'memberAssign')
            .then((response) => {
              if (response === "SUCCESS") {
                setIsLoading(false);
                if (props.setLoadTicket) props.setLoadTicket(true);
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });

          // handleSubmit(updatedValues, { setSubmitting: () => {} });
        }
      }
    }
  }, [props.sendPressed]);

  const mergeTicketFields = (allFields: any[], ticketFields: any[]) => {
    return allFields?.map((field) => {
      const ticketField = ticketFields.find(
        (f) => f.ticketField.type === field.type
      );

      const isMemberPresent = ticketFields.find(
        (f) => f.ticketField.type === "member"
      );

      if (isMemberPresent === undefined || isMemberPresent.value === "") {
        props.setIsMemberSelected(false);
      } else {
        props.setIsMemberSelected(true);
      }

      return {
        ...field,
        value: ticketField ? ticketField.value : "",
      };
    });
  };

  const initialValues = mergeTicketFields(
    allDefaultTicketFields,
    ticketFields
  ).reduce((acc, field) => {
    acc[field.type] = field.value;
    return acc;
  }, {});
  // Extract additional fields
  const initialAddOnsValues = mergeTicketFields(
    allAddOnsFields,
    ticketFields
  ).reduce((acc, field) => {
    acc[field.type] = field.value.toString().toLowerCase();
    return acc;
  }, {});

  const addOnsFields = ticketFields.filter(
    (field: any) => !field.ticketField.isDefault
  );

  useEffect(() => {
    if (addOnsFields.length === 0) {
      setActiveTab(1);
    }
  }, [addOnsFields]);

  function getDurationFromNow(timestamp: string): string {
    const currentDate = new Date().getTime();
    const givenDate = new Date(timestamp).getTime();

    const differenceInMilliseconds = currentDate - givenDate;

    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;

    const days = Math.floor(differenceInMilliseconds / msInDay);
    const hours = Math.floor((differenceInMilliseconds % msInDay) / msInHour);
    const minutes = Math.floor(
      (differenceInMilliseconds % msInHour) / msInMinute
    );

    if (days > 0) {
      return `(${days}d)`;
    } else if (hours > 0) {
      return `(${hours}h)`;
    } else {
      return `(${minutes}m)`;
    }
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const updatedFields = allDefaultTicketFields.map((field) => {
      const value = values[field.name] || "";
      return {
        ticketField: { ...field },
        value: value,
      };
    });

    const updatedAddOnsFields = allAddOnsFields.map((field) => {
      const value = values[field.name] || "";
      return {
        ticketField: { ...field },
        value: value,
      };
    });

    setIsLoading(true);

    const payload = {
      uid: props.selectedTicket.uid,
      fields: [...updatedFields, ...updatedAddOnsFields],
    };

    patchTicketPropertiesUpdate(payload.uid, payload.fields)
      .then((response) => {
        if (response === "SUCCESS") {
          setIsLoading(false);
          // if (props.setLoadTicket) props.setLoadTicket(true);
        }
        props.setCallStatusCount(true);
        // props.setSelectedTicket(props.selectedTicket);
      })
      .catch((error) => {
        setIsLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <div className="ticketUpdate">
      <div className="ticketUpdate-header">
        <Row>
          {resolutionTimeDue && (
            <>
              <Col
                md={6}
                className="ticket-details"
                style={{ color: "#63686F" }}
              >
                Resolution Due :
              </Col>
              <Col md={6} className="ticket-details">
                <TooltipReference
                  placement="top"
                  tooltipId="resolutionOverdue"
                  content={`From ${moment(
                    resolutionTimeDue
                  ).format("DD MMM YYYY hh:mm a")}`}
                >
                  <div className="ticketOverdue">
                    <span>Overdue</span>
                    <span className="pl-1">
                      {getDurationFromNow(
                        resolutionTimeDue
                      )}
                    </span>
                  </div>
                </TooltipReference>
              </Col>
            </>
          )}
          {firstResponseTimeDue && (
            <>
              <Col
                md={6}
                className="ticket-details mt-1"
                style={{ color: "#63686F" }}
              >
                First Response Due :
              </Col>
              <Col md={6} className="ticket-details mt-1">
                <TooltipReference
                  placement="top"
                  tooltipId="firstResponseDue"
                  content={`From ${moment(
                    firstResponseTimeDue
                  ).format("DD MMM YYYY hh:mm a")}`}
                >
                  <div className="ticketOverdue">
                    <span>Overdue</span>
                    <span className="pl-1">
                      {getDurationFromNow(
                        firstResponseTimeDue
                      )}
                    </span>
                  </div>
                </TooltipReference>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div className="ticketUpdate-body">
        <div className="ticketUpdate-body-header">
          <div
            className={`header${activeTab === 1 ? "active" : ""}`}
            style={{ marginRight: "12px" }}
            onClick={() => setActiveTab(1)}
          >
            Default Fields
          </div>
          {addOnsFields.length > 0 && (
            <div
              className={`header${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              AddOns
            </div>
          )}
        </div>

        <Formik
          enableReinitialize
          initialValues={{ ...initialValues, ...initialAddOnsValues }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values, dirty }) => {
            const isStatusClosed = values["Status"] === "Closed";

            useEffect(() => {
              if (isStatusClosed) {
                Object.keys(values).forEach((fieldName) => {
                  if (fieldName !== "Status") {
                    setFieldValue(fieldName, values[fieldName]);
                  }
                });
              }
            }, [isStatusClosed, values, setFieldValue]);

            return (
              <>
                {activeTab === 1 ? (
                  <>
                    <Form>
                      <div
                        className="ticketForm-update"
                        ref={formRef}
                        style={{
                          height:
                            props.selectedTicket?.resolutionTimeDue &&
                            props.selectedTicket?.firstResponseTimeDue
                              ? "55vh"
                              : props.selectedTicket?.resolutionTimeDue
                              ? "60vh"
                              : "65vh",
                        }}
                      >
                        {allDefaultTicketFields.map((field: any) => (
                          <div className="form-field" key={field.id}>
                            {field.dropDown ? (
                              <Field name={field.name}>
                                {({ field: formikField, form }: any) => {
                                  let options: Option[] = [];
                                  if (field.type === "team") {
                                    options = teams;
                                  } else if (field.type === "member") {
                                    options = members;
                                  } else {
                                    options = field.options
                                      ?.filter((option: any) => !option.deleted)
                                      .map((opt: any) => ({
                                        label: opt.label,
                                        value: opt.label,
                                      }));
                                  }
                                  return (
                                    <>
                                      <small>{field.label}</small>
                                      <Select
                                        options={options}
                                        className="pt-1"
                                        components={animatedComponents}
                                        value={
                                          options.find(
                                            (option) =>
                                              option?.value === formikField?.value
                                          ) || {
                                            label: "",
                                            value: "",
                                          }
                                        }
                                        placeholder={`${field.label}...`}
                                        onChange={(option: any) => {
                                          if (field.type === "team") {
                                            handleTeamChange(option, {
                                              field: formikField,
                                              form,
                                            });
                                          } else {
                                            form.setFieldValue(
                                              formikField.name,
                                              option?.value
                                            );
                                          }
                                        }}
                                        styles={customStyles}
                                        isDisabled={
                                          (isStatusClosed &&
                                          field.name !== "status") || (userProfile.role.type === "MEMBER" && field.name === 'member')
                                        }
                                        onMenuOpen={() => setMenuOpen(true)}
                                        onMenuClose={() => setMenuOpen(false)}
                                      />
                                    </>
                                  );
                                }}
                              </Field>
                            ) : (
                              <div>
                                <small>{field.label}</small>
                                <Field
                                  name={field.name}
                                  as={Input}
                                  type={
                                    field.type === "number" ? "number" : "text"
                                  }
                                  className="form-field"
                                  disabled={
                                    isStatusClosed && field.name !== "status"
                                  }
                                  style={{ width: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div
                        className="form-footer"
                        style={{ cursor: "not-allowed" }}
                      >
                        <Button
                          type="submit"
                          disabled={!dirty}
                          style={{
                            cursor: dirty ? "pointer" : "not-allowed",
                            opacity: dirty ? 1 : 0.4,
                          }}
                        >
                          <div className="d-flex">
                            <div className="m-auto">
                              {isLoading ? "Wait" : "Update"}
                            </div>
                            {isLoading && <div className="loadingVia"></div>}
                          </div>
                        </Button>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="addOns-section">
                      {allAddOnsFields.map((field: TicketField) => (
                        <div className="mt-3" key={field.id}>
                          <small>{field.label}</small>
                          <Field
                            name={field.name}
                            as={Input}
                            type={field.type === "number" ? "number" : "text"}
                            className="form-field"
                            disabled
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default TicketUpdate;
