import React, { useEffect, useState, useRef, useCallback } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Col,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { socket } from "../../../../common/socketconfig";
import Modall from "../../../../common/modall";
import TemplateList from "../../../Template/templateList";
import ProgressBar from "../../../../common/circularProgressbar";
import { useData } from "../../../../contexts/tempContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../../../common/alert";
import { sendConvImage } from "../../../../services/conversationservice";
import TicketCreatedComponent from "./TicketCreatedComponent";
import TicketConversation from "./TicketConversation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "../styles.scss";
import FilePlaceholder from "../../../../botFlows/components/designFlow/nodes/sendMedia/filePlaceholder";
import TypeListComponent from "./TypeListComponent";
import { Overlay } from "react-bootstrap";
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";
import { Popover as RsPopover } from "reactstrap";
import { Popover as RbPopover } from "react-bootstrap";

interface Option {
  label: string;
  value: string | number;
}

interface TicketField {
  id: number;
  name: string;
  label: string;
  position: number;
  type: string;
  dropDown: boolean;
  options: Option[];
}

interface SendTicketProps {
  data: any;
  allTickets: any[];
  setAllTickets: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTicket: any;
  setSelectedTicket: React.Dispatch<React.SetStateAction<any>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchVal: string;
  statusCounts: any;
  setStatusCounts: React.Dispatch<React.SetStateAction<any>>;
  setSendPressed: React.Dispatch<React.SetStateAction<boolean>>;
  typeSelection: any;
  setTypeSelection: React.Dispatch<React.SetStateAction<any>>;
  ticketFields: TicketField[];
  isType: boolean;
  setIsType: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTypes: any;
  ticketTypesReport: any;
  typeStatusSelected: string;
  setTypeStatusSelected: React.Dispatch<React.SetStateAction<string>>;
}

type ReactQuillInstance = {
  focus(): unknown;
  getEditor: () => any;
  makeUnprivilegedEditor: (editor: any) => { root: HTMLElement };
  editingArea: any;
};

const SendTicket: React.FC<SendTicketProps> = (props) => {
  const [currentMessage, setCurrentMsg] = useState<string>("");
  const [notes, setNotes] = useState<boolean>(false);
  const [templates, setTemplates] = useState<boolean>(false);
  const [ticketMsgs, setTicketMsgs] = useState<any[]>([]);
  const sendticketsocket = socket;
  const business = useSelector((state: any) => state.cartreducer.business);
  const channelUid = useSelector((state: any) => state.cartreducer.channelUid);
  const sendTicketBodyRef = useRef<HTMLDivElement | null>(null);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [templateModal, setTemplateModal] = useState<boolean>(false);
  const [modalSize, setModalSize] = useState<string>("modal-xxl");
  const [modalHead, setModalTiitle] = useState<string>("Select Template");
  const [convLoader, setConvLoader] = useState<boolean>(false);
  const editableRef = useRef<ReactQuillInstance | null>(null);
  const { data, clearData, componentName } = useData();
  const [templateData, setTemplateData] = useState<any>({});
  const [mediaType, setMediaType] = useState<string>("");
  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const [innerMediatype, setInnerMediaType] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [previewInput, setPreviewInput] = useState<string>("");
  const targetType = useRef<HTMLDivElement>(null);

  // const handleLoadTicketMessages = useCallback((data: any) => {
  //   setConvLoader(true);
  //   console.log(data);
  //   setTicketMsgs(data.reverse());
  //   setTimeout(() => {
  //     setConvLoader(false);
  //   }, 500);
  // }, []);

  const handleTypeSelection = (selectedTypes: string[]) => {
    props.setTypeSelection(selectedTypes);
  };

  const handleUploadClick = (type: string) => {
    setPreviewModalOpen(false);
    if (fileInputRef.current) {
      switch (type) {
        case "image":
          fileInputRef.current.accept = "image/*";
          break;
        case "video":
          fileInputRef.current.accept = "video/*";
          break;
        case "doc":
          fileInputRef.current.accept = ".pdf,.xls,.xlsx,.txt,.doc,.docx";
          break;
      }
      fileInputRef.current.click();
    }
    setInnerMediaType(type);
    setMediaType("media");
  };

  const millisecondsToHoursAndMinutes = (
    durationInMilliseconds: number
  ): string => {
    const hours: number = Math.floor(durationInMilliseconds / 3600000);
    const minutes: number = Math.floor(
      (durationInMilliseconds % 3600000) / 60000
    );
    let formattedDuration: string = "";
    if (hours > 0) {
      formattedDuration += `${hours} ${hours === 1 ? "hr" : "hrs"}`;
    }
    if (minutes > 0) {
      if (formattedDuration.length > 0) {
        formattedDuration += " and ";
      }
      formattedDuration += `${minutes} ${minutes === 1 ? "min" : "mins"}`;
    }
    return formattedDuration;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFiles: {
      imgUrl: string;
      imgName: string;
      image: File;
      type: string;
      size: number;
      innerType: "video" | "image" | "document";
    }[] = [];
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    let validFileType = false;

    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];
      const imgUrl = URL.createObjectURL(newFile);

      const MAX_VIDEO_SIZE_MB = 16; // Maximum allowed video size in MB
      const MAX_VIDEO_SIZE_BYTES = MAX_VIDEO_SIZE_MB * 1024 * 1024;

      switch (innerMediatype) {
        case "image":
          if (
            newFile.type === "image/jpeg" ||
            newFile.type === "image/png" ||
            newFile.type === "image/jpg"
          ) {
            updatedFiles.push({
              imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "image",
            });
            validFileType = true;
            setFiles(updatedFiles);
            setPreviewUrl(imgUrl);
          } else {
            toast("error", "Invalid file format");
            setPreviewModalOpen(false);
          }
          break;
        case "video":
          if (newFile.type === "video/mp4") {
            if (newFile.size > MAX_VIDEO_SIZE_BYTES) {
              toast(
                "error",
                `Video should not exceed more than ${MAX_VIDEO_SIZE_MB}MB`
              );
              setPreviewModalOpen(false);
              return; // Do not proceed further if video exceeds maximum size
            }
            updatedFiles.push({
              imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "video",
            });
            validFileType = true;
            setFiles(updatedFiles);
            setPreviewUrl(imgUrl);
          } else {
            toast("error", "Invalid file format");
            setPreviewModalOpen(false);
          }
          break;
        case "doc":
          if (allowedFileTypes.includes(newFile.type)) {
            updatedFiles.push({
              imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "document",
            });
            validFileType = true;
            setFiles(updatedFiles);
            setPreviewUrl(
              `https://docs.google.com/gview?url=${imgUrl}&embedded=true`
            );
          } else {
            toast("error", "Invalid file format");
            setPreviewModalOpen(false);
          }
          break;
      }

      if (validFileType) {
        setPreviewModalOpen(true);
      }
      togglePopover();
      setSelectedTool("");
      setMediaType("media");
    }
  };

  const selectItem = (itemId: string) => {
    if (selectedTool === itemId) {
      setSelectedTool(null);
      switch (itemId) {
        case "notes":
          {
            setNotes(false);
            setSelectedTool("");
            setPopoverOpen(false);
          }
          break;
      }
    } else {
      setSelectedTool(itemId);
      switch (itemId) {
        case "sendSvg":
          togglePopover();
          break;
      }
    }
  };

  useEffect(() => {
    if (!convLoader && sendTicketBodyRef.current) {
      sendTicketBodyRef.current.scrollTop =
        sendTicketBodyRef.current.scrollHeight;
    }
  }, [convLoader, ticketMsgs]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (targetType.current && !targetType.current.contains(event.target as Node)) {
        props.setIsType(false);
      }
    };
  
    document.addEventListener("click", handleDocumentClick);
  
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [targetType, props.setIsType]);

  // useEffect(() => {
  //   sendticketsocket.on("loadMoreTickets", (newTickets: any) => {
  //     if (newTickets && newTickets.length > 0) {
  //       setTicketMsgs((prevMsgs) => [...newTickets.reverse(), ...prevMsgs]);
  //       setHasMoreMessages(newTickets.length === 10);
  //     } else {
  //       setHasMoreMessages(false);
  //     }
  //     setConvLoader(false);
  //   });
  // }, []);

  const handleScroll = () => {
    if (sendTicketBodyRef.current) {
      const { scrollTop } = sendTicketBodyRef.current;
      if (scrollTop === 0 && !convLoader) {
        props.setPage(props.page + 1);
      }
    }
  };

  useEffect(() => {
    const container = sendTicketBodyRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (
      props.data &&
      (Object.entries(props.data).length || props.data.length)
    ) {
      setRemainingTime(props.data?.conversation?.remainingTime);
      sendticketsocket.on("onLoadTicketMessages", (data: any) => {
        setConvLoader(true);
        const ticketMsgs = data.reverse();
        setTicketMsgs(ticketMsgs);
        setTimeout(() => {
          setConvLoader(false);
        }, 500);
      });
      sendticketsocket.on("onTicket", (payload: any) => {
        if (payload.id === props.data.id) props.setSelectedTicket(payload);
        if (
          (business?.role?.name === "MEMBER"
            ? (payload.latestMessage.assignTo === 2 &&
                business?.id === payload.latestMessage.assignId) ||
              payload.latestMessage.assignTo === 1
              ? true
              : false
            : true) &&
          !props.searchVal
        ) {
          const ticketExists = props.allTickets.some(
            (tik: any) => tik.id === payload.id
          );
          let tickets;
          if (ticketExists) {
            tickets = props.allTickets.map((tik: any) =>
              tik.id === payload.id ? payload : tik
            );
          } else {
            tickets = [payload, ...props.allTickets];
          }
          props.setAllTickets(tickets);
        }
      });
      setNotes(false);
      return () => {
        //sendticketsocket.off("onLoadTicketMessages");
        //sendticketsocket.off("onMessage");
        //sendticketsocket.off("onTicket");
      };
    } else {
      setTicketMsgs([]);
    }
  }, [props.data]);

  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.focus();
    }
  }, [props.data]);

  useEffect(() => {
    //if (ticketMsgs.length) {
    sendticketsocket.on(
      `onMessage/${business?.business?.uid}`,
      (payload: any) => {
        if (payload.ticketMessage) {
          if (payload.ticketId === props.data.id) {
            const msgs = [...ticketMsgs];
            msgs.push(payload);
            setTicketMsgs(msgs);
            setRemainingTime(payload.conversation.remainingTime);
            setTimeout(() => {
              if (sendTicketBodyRef.current) {
                sendTicketBodyRef.current.scrollTop =
                  sendTicketBodyRef.current.scrollHeight;
              }
            }, 100);
          }
          const updateTickets = props.allTickets.map((ticket) => {
            if (ticket.id === payload.ticketId) {
              const { conversation, ...restMessages } = payload;
              const latestMessage = restMessages;
              return {
                ...ticket,
                latestMessage,
                conversation,
              };
            }
            return ticket;
          });
          props.setAllTickets(updateTickets);
        }
      }
    );
    //}
  }, [ticketMsgs]);

  const handleInputChange = (message: string) => {
    setCurrentMsg(message);
  };

  const convertHTMLToCustomFormat = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");

    const traverse = (node: Node): string => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.nodeValue || "";
      }
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return "";
      }
      const element = node as HTMLElement;
      const content = Array.from(element.childNodes).map(traverse).join("");

      let formattedContent = content;

      if (
        element.tagName === "STRONG" &&
        element.querySelector("EM") &&
        element.querySelector("S")
      ) {
        formattedContent = `***~~${formattedContent}~~***`;
      } else if (element.tagName === "STRONG" && element.querySelector("EM")) {
        formattedContent = `***${formattedContent}***`;
      } else if (element.tagName === "STRONG" && element.querySelector("S")) {
        formattedContent = `**~~${formattedContent}~~**`;
      } else if (element.tagName === "EM" && element.querySelector("S")) {
        formattedContent = `*~~${formattedContent}~~*`;
      } else {
        if (element.tagName === "STRONG" || element.tagName === "B") {
          formattedContent = `*${formattedContent}*`;
        }
        if (element.tagName === "EM" || element.tagName === "I") {
          formattedContent = `_${formattedContent}_`;
        }
        if (element.tagName === "S" || element.tagName === "STRIKE") {
          formattedContent = `~${formattedContent}~`;
        }
      }

      return formattedContent;
    };

    return traverse(doc.body);
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      setTemplateData(data);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(templateData).length && componentName === "ticket") {
      setMediaType("template");
    }
  }, [templateData, componentName]);

  useEffect(() => {
    if (mediaType === "template") {
      const payload = {
        businessId: business.business.uid,
        channelId: channelUid.value,
        conversationUid: props.data.conversation?.uid,
        ticketId: props.data.id,
        contactId: props.data.contact?.uid,
        content: convertHTMLToCustomFormat(currentMessage),
        type: "component",
        subType: "template",
        mobileNumber: props.data.contact.mobileNumber,
        template: templateData,
        assignId: business?.id,
        assignTo: 2,
      };
      sendticketsocket.off("onMessage");
      sendticketsocket.emit("ticketMessage", payload);
      setMediaType("");
      setTemplateData({});
    }
  }, [mediaType]);

  const stripHtmlTags = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleEnterKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    type?: string
  ) => {
    if ((e.key === "Enter" && !e.shiftKey) || type === "sendButton") {
      e.preventDefault();
      const formattedMessage = convertHTMLToCustomFormat(currentMessage);

      const payloadToSend = {
        businessId: business.business.uid,
        channelId: channelUid.value,
        conversationUid: props.data.conversation.uid,
        ticketId: props.data.id,
        contactId: props.data.contact?.uid,
        content: formattedMessage,
        type: "text",
        subType: "text",
        mobileNumber: props.data.contact.mobileNumber,
        assignId: business?.id,
        assignTo: 2,
        note: notes,
      };
      sendticketsocket.off("onMessage");
      sendticketsocket.emit("ticketMessage", payloadToSend);
      setCurrentMsg("");
      setMediaType("");
      setFiles([]);
      props.setSendPressed(true);
    }
  };

  const handleSendMediaMessage = (filesToSend = files) => {
    const payload = {
      uid: channelUid.value,
      caption: previewInput,
      fileData: filesToSend[0],
    };

    sendConvImage(payload).then((res) => {
      if (res) {
        const formattedMessage = convertHTMLToCustomFormat(currentMessage);
        const payloadToSend = {
          businessId: business.business.uid,
          channelId: channelUid.value,
          conversationUid: props.data.conversation.uid,
          ticketId: props.data.id,
          contactId: props.data.contact?.uid,
          content: formattedMessage,
          type: "media",
          subType: filesToSend[0].innerType
            ? filesToSend[0].innerType
            : "image",
          mobileNumber: props.data.contact.mobileNumber,
          assignId: business?.id,
          assignTo: 2,
          media: res,
          note: notes,
        };

        sendticketsocket.off("onMessage");
        sendticketsocket.emit("ticketMessage", payloadToSend);
        setCurrentMsg("");
        setMediaType("");
        setPreviewInput("");
        setFiles([]);
        props.setSendPressed(true);
      }
    });
  };

  const handleClose = () => {
    setTemplateModal(false);
    setModalSize("modal-xxl");
  };

  const modalTitle = (title: string) => {
    setModalTiitle(title);
  };

  const extractTextFromHTML = (htmlString: string): string => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const paragraphs = doc.body.querySelectorAll("p");
    let text = "";
    paragraphs.forEach((paragraph: Element, index: number) => {
      const paragraphText = paragraph.textContent;
      text += paragraphText;
      if (index < paragraphs.length - 1) {
        text += "\n";
      }
    });
    return text;
  };

  const ticketSubject = props.data?.fields?.filter(
    (f: any) => f.type === "subject"
  )[0]?.value;

  const badgeStatus = props.data?.fields
    ?.filter((f: any) => f.type === "status")[0]
    ?.value.toLowerCase();
  let badgeColor =
    badgeStatus === "closed"
      ? "#A7A9B2"
      : badgeStatus === "pending"
      ? "#DFCD24"
      : badgeStatus === "resolved"
      ? "#6EC77C"
      : badgeStatus === "open"
      ? "#007EC3"
      : "";

  const status = ["Open", "Pending", "Resolved", "Closed"];

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       targetType.current &&
  //       !targetType.current.contains(event.target as Node)
  //     ) {
  //       setPopoverOpen(false);
  //     }
  //   };

  //   if (popoverOpen) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popoverOpen, targetType])
  return (
    <div className="send-ticket">
      <div className="mini-filter-container">
        <div className="mini-filter">
          <div
            ref={targetType}
            onClick={() => {
              props.setIsType((current) => !current);
              props.setTicketFilterVisible(false);
              props.setTypeStatusSelected("");
            }}
            className="d-flex align-items-center"
            style={{cursor: 'pointer'}}
          >
            <div className="filter-title" style={{background: (props.isType || props.selectedTypes.length > 0) ? '#00b598' : '' , color: (props.isType || props.selectedTypes.length > 0) ? 'white' : ''}}>
              <div className="me-2">
                {props.selectedTypes.length > 0 ? props.selectedTypes : "All Types"}
              </div>
              <div>
                {props.isType ? (
                  <IoChevronUpOutline size={15} />
                ) : (
                  <IoChevronDownOutline size={15} />
                )}
              </div>
            </div>
            <Overlay
              target={targetType.current}
              show={props.isType}
              placement="bottom"
              onHide={() => props.setIsType(false)} // Add this event handler
            >
              <RbPopover
                style={{
                  maxHeight: "50%",
                  // overflowY: "scroll",
                  // overflowX: "hidden",
                  margin: "-5px",
                  padding: "0px",
                }}
                >
                <RbPopover.Body 
                  style={{ 
                    padding: "0.5rem",
                    }}>
                  <TypeListComponent
                    typeOptions={
                      props.ticketFields
                        .find((field) => field.type === "type")
                        ?.options.filter((option: any) => !option.deleted).map((option: any) => ({
                          id: option.id.toString(),
                          label: option.label,
                        })) || []
                    }
                    typeCount={props.ticketTypesReport}
                    selectedTypes={props.selectedTypes}
                    onApply={handleTypeSelection}
                  />
                </RbPopover.Body>
              </RbPopover>
            </Overlay>
          </div>
          <div className="d-flex">
            {status.map((el: string, inx: number) => {
              let badgeColor =
                el === "Closed"
                  ? "#A7A9B2"
                  : el === "Pending"
                  ? "#DFCD24"
                  : el === "Resolved"
                  ? "#6EC77C"
                  : el === "Open"
                  ? "#007EC3"
                  : "";
              return (
                <div key={el} className="status-count-component"
                  style={{
                      borderTopRightRadius: inx === 3 ? "10px" : "0px", 
                      borderBottomRightRadius: inx === 3 ? "10px" : "0px",
                      opacity: props.statusCounts[el] > 0 ? "1" : "0.5",
                      cursor: props.statusCounts[el] > 0 ? "pointer" : "not-allowed",
                    }}
                  onClick={(e:any) => {
                    if(props.statusCounts[el] > 0){
                      props.setTypeStatusSelected(el);
                    }else{
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  id={`${props.typeStatusSelected && props.typeStatusSelected === el ? el : ""}`}
                  >
                  <div 
                    // id={`${props.typeStatusSelected && props.typeStatusSelected === el ? el : ""}`}
                    className="status-label">{el}</div>
                  <div style={{ color: badgeColor }} className="status-count ms-2">
                    {props.statusCounts[el]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="send-ticket-header d-flex align-items-center">
        <div
          className="ticket-header-title"
          style={{
            border: `1px solid ${badgeColor}`,
            background: badgeColor,
            color: "white",
          }}
        >
          # {props.data?.id}
        </div>
        <div className="ms-2">- {ticketSubject}</div>
        <div className="ml-auto">
          {props.data?.conversation?.remainingTime > 0 && (
            <OverlayTrigger
              overlay={
                <Tooltip className="single-line-tooltip">
                  The chat will expire in{" "}
                  {millisecondsToHoursAndMinutes(
                    props.data?.conversation?.remainingTime
                  )}
                  . Once expired, WhatsApp will only permit the sending of
                  template messages.
                </Tooltip>
              }
              placement="bottom"
              trigger={["hover", "focus"]}
            >
              <button className="no-button">
                <ProgressBar
                  totalMilliseconds={props.data?.conversation?.remainingTime}
                  width={40}
                  height={40}
                  color="#00B598"
                />
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>

      <div className="send-ticket-body">
        <div className="ticketCreated">
          <TicketCreatedComponent ticketdata={props.data} />
        </div>
        <div className="ticketConvs" ref={sendTicketBodyRef}>
          {convLoader ? (
            <div className="convLoading__wrapper">
              <div className="convLoading">
                <Spinner size="sm" />
                <div className="loadingText">Loading...</div>
              </div>
            </div>
          ) : (
            <TicketConversation
              conversation={ticketMsgs}
              selectedTicket={props.data}
            />
          )}
        </div>
      </div>
      {(remainingTime > 0 || notes) && badgeStatus !== "closed" ? (
        <div
          className="ticketInput mt-3"
          id={`quillEditor-${notes ? "notes" : "chat"}`}
        >
          <div className="ticket-input">
            <div className="input-quill">
              <ReactQuill
                ref={editableRef}
                value={currentMessage}
                onChange={(content: any) => setCurrentMsg(content)}
                autoFocus={true}
                modules={{
                  toolbar: {
                    container: "#toolbar",
                  },
                }}
                selection={{ index: 0, length: 0 }}
                placeholder="Enter Message"
                className={`quillEditor`}
                onKeyDown={handleEnterKeyPress}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <div id="toolbar">
                  <UncontrolledTooltip placement="top" target="Tooltip-Bold">
                    Bold
                  </UncontrolledTooltip>
                  <button
                    id="Tooltip-Bold"
                    className="ql-bold"
                    disabled={!convertHTMLToCustomFormat(currentMessage)}
                  ></button>
                  <UncontrolledTooltip placement="top" target="Tooltip-Italic">
                    Italic
                  </UncontrolledTooltip>
                  <button
                    id="Tooltip-Italic"
                    className="ql-italic"
                    disabled={!convertHTMLToCustomFormat(currentMessage)}
                  ></button>
                  <UncontrolledTooltip
                    placement="top"
                    target="Tooltip-Strikethrough"
                  >
                    Strikethrough
                  </UncontrolledTooltip>
                  <button
                    id="Tooltip-Strikethrough"
                    className="ql-strike"
                    disabled={!convertHTMLToCustomFormat(currentMessage)}
                  ></button>
                </div>
                <UncontrolledTooltip placement="top" target="filesPopover">
                  Attachment
                </UncontrolledTooltip>
                <FontAwesomeIcon
                  icon={faPaperclip}
                  className="ml-2 mb-2 mt-1"
                  onClick={() => selectItem("sendSvg")}
                  id="filesPopover"
                  color="#90939F"
                  fontSize={16}
                />
                <RsPopover
                  placement="top"
                  isOpen={popoverOpen}
                  target="filesPopover"
                  toggle={togglePopover}
                  trigger="legacy"
                  className="convoUploadPopup"
                >
                  <PopoverBody>
                    <div
                      className="convoUploadPopup_item hover-red"
                      onClick={() => handleUploadClick("image")}
                    >
                      <Row>
                        <Col className="col-1">
                          <span className="mr-2">
                            <svg
                              width="28"
                              height="20"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.625 4.375H7.875C7.41087 4.375 6.96575 4.55937 6.63756 4.88756C6.30937 5.21575 6.125 5.66087 6.125 6.125V7.875H4.375C3.91087 7.875 3.46575 8.05937 3.13756 8.38756C2.80937 8.71575 2.625 9.16087 2.625 9.625V21.875C2.625 22.3391 2.80937 22.7842 3.13756 23.1124C3.46575 23.4406 3.91087 23.625 4.375 23.625H20.125C20.5891 23.625 21.0342 23.4406 21.3624 23.1124C21.6906 22.7842 21.875 22.3391 21.875 21.875V20.125H23.625C24.0891 20.125 24.5342 19.9406 24.8624 19.6124C25.1906 19.2842 25.375 18.8391 25.375 18.375V6.125C25.375 5.66087 25.1906 5.21575 24.8624 4.88756C24.5342 4.55937 24.0891 4.375 23.625 4.375ZM7.875 6.125H23.625V12.9883L22.5236 11.888C22.3611 11.7254 22.1682 11.5965 21.9558 11.5085C21.7435 11.4205 21.5159 11.3753 21.286 11.3753C21.0562 11.3753 20.8286 11.4205 20.6162 11.5085C20.4039 11.5965 20.2109 11.7254 20.0484 11.888L17.8609 14.0755L13.0484 9.26297C12.7203 8.93503 12.2753 8.75081 11.8114 8.75081C11.3475 8.75081 10.9025 8.93503 10.5744 9.26297L7.875 11.9623V6.125ZM20.125 21.875H4.375V9.625H6.125V18.375C6.125 18.8391 6.30937 19.2842 6.63756 19.6124C6.96575 19.9406 7.41087 20.125 7.875 20.125H20.125V21.875ZM23.625 18.375H7.875V14.4375L11.8125 10.5L17.2441 15.9316C17.4081 16.0955 17.6306 16.1876 17.8626 16.1876C18.0945 16.1876 18.317 16.0955 18.4811 15.9316L21.2877 13.125L23.625 15.4634V18.375ZM17.5 9.1875C17.5 8.92791 17.577 8.67415 17.7212 8.45831C17.8654 8.24247 18.0704 8.07425 18.3102 7.97491C18.5501 7.87557 18.814 7.84958 19.0686 7.90022C19.3232 7.95086 19.557 8.07587 19.7406 8.25942C19.9241 8.44298 20.0491 8.67684 20.0998 8.93144C20.1504 9.18604 20.1244 9.44994 20.0251 9.68977C19.9258 9.9296 19.7575 10.1346 19.5417 10.2788C19.3258 10.423 19.0721 10.5 18.8125 10.5C18.4644 10.5 18.1306 10.3617 17.8844 10.1156C17.6383 9.86944 17.5 9.5356 17.5 9.1875Z"
                                fill="#666E7D"
                              />
                            </svg>
                          </span>
                        </Col>
                        <Col className="ms-2">Upload Image</Col>
                      </Row>
                    </div>
                    <div
                      className="convoUploadPopup_item  "
                      onClick={() => handleUploadClick("video")}
                    >
                      <Row>
                        <Col className="col-1 ms-2">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#666E7D"
                              className="bi bi-camera-video"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                              />
                            </svg>
                          </span>
                        </Col>
                        <Col>Upload Video</Col>
                      </Row>
                    </div>
                    <div
                      className="convoUploadPopup_item  hover-purple"
                      onClick={() => handleUploadClick("doc")}
                    >
                      <Row>
                        <Col className="col-1">
                          <span className="mr-2">
                            <svg
                              width="28"
                              height="20"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.3691 9.00594L17.2441 2.88094C17.1627 2.79971 17.0662 2.7353 16.96 2.69138C16.8538 2.64747 16.7399 2.62491 16.625 2.625H6.125C5.66087 2.625 5.21575 2.80937 4.88756 3.13756C4.55937 3.46575 4.375 3.91087 4.375 4.375V23.625C4.375 24.0891 4.55937 24.5342 4.88756 24.8624C5.21575 25.1906 5.66087 25.375 6.125 25.375H21.875C22.3391 25.375 22.7842 25.1906 23.1124 24.8624C23.4406 24.5342 23.625 24.0891 23.625 23.625V9.625C23.6251 9.51006 23.6025 9.39623 23.5586 9.29001C23.5147 9.18379 23.4503 9.08726 23.3691 9.00594ZM17.5 5.61203L20.638 8.75H17.5V5.61203ZM21.875 23.625H6.125V4.375H15.75V9.625C15.75 9.85706 15.8422 10.0796 16.0063 10.2437C16.1704 10.4078 16.3929 10.5 16.625 10.5H21.875V23.625Z"
                                fill="#666E7D"
                              />
                            </svg>
                          </span>
                        </Col>
                        <Col className="ms-2">Upload Document</Col>
                      </Row>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*,video/*,.pdf,.xls,.xlsx,.txt,.doc,.docx"
                      onChange={handleFileChange}
                    />
                  </PopoverBody>
                </RsPopover>
              </div>
              <div>
                <div className="input-extras">
                  <div onClick={() => setNotes(!notes)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.9257 10.5391L17.3566 8.92914C17.8599 7.04997 18.1124 6.11081 17.9232 5.29747C17.7733 4.65539 17.4363 4.07219 16.9549 3.62164C16.3457 3.05081 15.4057 2.79914 13.5266 2.29581C11.6474 1.79164 10.7074 1.53997 9.89491 1.72914C9.25282 1.87907 8.66962 2.21605 8.21908 2.69747C7.72991 3.21914 7.47491 3.98331 7.09575 5.37164L6.89241 6.12581L6.46158 7.73581C5.95741 9.61497 5.70575 10.5541 5.89491 11.3675C6.04484 12.0096 6.38183 12.5928 6.86325 13.0433C7.47241 13.6141 8.41241 13.8658 10.2916 14.37C11.9849 14.8233 12.9157 15.0725 13.6782 14.9783C13.7616 14.9683 13.8432 14.9533 13.9232 14.935C14.5651 14.7855 15.1483 14.4491 15.5991 13.9683C16.1699 13.3583 16.4216 12.4183 16.9257 10.5391Z"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M13.6769 14.9783C13.5027 15.5119 13.1966 15.9929 12.7869 16.3766C12.1778 16.9475 11.2378 17.1991 9.35861 17.7025C7.47944 18.2058 6.53944 18.4583 5.72694 18.2683C5.08495 18.1186 4.50176 17.7819 4.05111 17.3008C3.48028 16.6916 3.22778 15.7516 2.72444 13.8725L2.29361 12.2625C1.78944 10.3833 1.53778 9.44329 1.72694 8.63079C1.87687 7.9887 2.21386 7.4055 2.69528 6.95495C3.30444 6.38412 4.24444 6.13246 6.12361 5.62829C6.47861 5.53329 6.80111 5.44662 7.09444 5.37079"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M9.81156 8.33325L13.8366 9.41158M9.16406 10.7483L11.5791 11.3949"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                    <div
                      className={`notesFontSize ${
                        notes ? "noteTemplateFont" : ""
                      }`}
                      onClick={() => setNotes(!notes)}
                    >
                      Notes
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setTemplates(true);
                      setTemplateModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="ms-1"
                    >
                      <path
                        d="M5.625 2.5C4.7962 2.5 4.00134 2.82924 3.41529 3.41529C2.82924 4.00134 2.5 4.7962 2.5 5.625V13.125C2.5 13.9538 2.82924 14.7487 3.41529 15.3347C4.00134 15.9208 4.7962 16.25 5.625 16.25H6.25V15H5.625C5.12772 15 4.65081 14.8025 4.29917 14.4508C3.94754 14.0992 3.75 13.6223 3.75 13.125V5.625C3.75 5.12772 3.94754 4.65081 4.29917 4.29917C4.65081 3.94754 5.12772 3.75 5.625 3.75H13.125C13.6223 3.75 14.0992 3.94754 14.4508 4.29917C14.8025 4.65081 15 5.12772 15 5.625V8.75H16.25V5.625C16.25 4.7962 15.9208 4.00134 15.3347 3.41529C14.7487 2.82924 13.9538 2.5 13.125 2.5H5.625ZM9.375 10C8.87772 10 8.40081 10.1975 8.04917 10.5492C7.69754 10.9008 7.5 11.3777 7.5 11.875V16.875C7.5 17.3723 7.69754 17.8492 8.04917 18.2008C8.40081 18.5525 8.87772 18.75 9.375 18.75H16.875C17.3723 18.75 17.8492 18.5525 18.2008 18.2008C18.5525 17.8492 18.75 17.3723 18.75 16.875V11.875C18.75 11.3777 18.5525 10.9008 18.2008 10.5492C17.8492 10.1975 17.3723 10 16.875 10H9.375ZM8.75 11.875C8.75 11.7092 8.81585 11.5503 8.93306 11.4331C9.05027 11.3158 9.20924 11.25 9.375 11.25H16.875C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875V12.48L13.125 14.91L8.75 12.48V11.875ZM8.75 13.9087L12.8212 16.1713C12.9142 16.2229 13.0187 16.25 13.125 16.25C13.2313 16.25 13.3358 16.2229 13.4288 16.1713L17.5 13.9087V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H9.375C9.20924 17.5 9.05027 17.4342 8.93306 17.3169C8.81585 17.1997 8.75 17.0408 8.75 16.875V13.9087ZM6.25 6.25C6.08424 6.25 5.92527 6.31585 5.80806 6.43306C5.69085 6.55027 5.625 6.70924 5.625 6.875C5.625 7.04076 5.69085 7.19973 5.80806 7.31694C5.92527 7.43415 6.08424 7.5 6.25 7.5H12.5C12.6658 7.5 12.8247 7.43415 12.9419 7.31694C13.0592 7.19973 13.125 7.04076 13.125 6.875C13.125 6.70924 13.0592 6.55027 12.9419 6.43306C12.8247 6.31585 12.6658 6.25 12.5 6.25H6.25Z"
                        fill={templateModal ? "#007EC3" : "#666E7D"}
                      />
                    </svg>
                    <div
                      className={`notesFontSize ${
                        templateModal ? "noteTemplateFont" : ""
                      }`}
                      onClick={() => {
                        setTemplates(true);
                        setTemplateModal(true);
                      }}
                    >
                      Templates
                    </div>

                    {templateModal && (
                      <Modall
                        isOpen={templates}
                        onClose={handleClose}
                        title={modalHead}
                        size={modalSize}
                      >
                        <TemplateList
                          component="ticket"
                          handleClose={handleClose}
                          modalTitle={modalTitle}
                          setModalSize={setModalSize}
                          selectedConversation={props.data}
                        />
                      </Modall>
                    )}
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 32 32"
                      fill="none"
                      className="mb-2 mr-1 ml-2"
                      style={{
                        cursor:
                          extractTextFromHTML(currentMessage).trim() !== ""
                            ? "pointer"
                            : "not-allowed",
                      }}
                      onClick={(e: any) => {
                        if (extractTextFromHTML(currentMessage).trim() !== "") {
                          handleEnterKeyPress(e, "sendButton");
                        }
                      }}
                    >
                      <path
                        d="M16.0013 0.166748C18.0806 0.166748 20.1395 0.576289 22.0605 1.37199C23.9814 2.16769 25.7269 3.33396 27.1972 4.80422C28.6674 6.27448 29.8337 8.01994 30.6294 9.94093C31.4251 11.8619 31.8346 13.9208 31.8346 16.0001C31.8346 20.1993 30.1665 24.2266 27.1972 27.1959C24.2278 30.1653 20.2006 31.8334 16.0013 31.8334C13.922 31.8334 11.8631 31.4239 9.94215 30.6282C8.02116 29.8325 6.27571 28.6662 4.80544 27.1959C1.83612 24.2266 0.167969 20.1993 0.167969 16.0001C0.167969 11.8008 1.83612 7.77355 4.80544 4.80422C7.77477 1.8349 11.802 0.166748 16.0013 0.166748ZM10.0165 9.35711C9.85155 9.28634 9.66797 9.40735 9.66797 9.58687V14.277C9.66797 14.4023 9.76076 14.5083 9.885 14.5248L19.1104 15.7523C19.3998 15.7908 19.3998 16.2094 19.1104 16.2479L9.885 17.4754C9.76076 17.4919 9.66797 17.5979 9.66797 17.7232V22.4133C9.66797 22.5928 9.85155 22.7138 10.0165 22.643L24.9658 16.2298C25.1677 16.1432 25.1677 15.857 24.9658 15.7703L10.0165 9.35711Z"
                        fill={
                          extractTextFromHTML(currentMessage).trim() !== ""
                            ? "#458d80"
                            : "#ccc"
                        }
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ticketTimeout">
          <div className="ticketTimeout-header">
            {badgeStatus === "closed"
              ? "The ticket is closed. Please change the status to update the ticket."
              : "WhatsApp allows you to send only pre-approved template messages."}
          </div>
          <div className="ticketTimeout-body">
            {badgeStatus !== "closed" && (
              <>
                <div className="close-notes">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 20 20"
                      fill="none"
                      onClick={() => setNotes(!notes)}
                    >
                      <path
                        d="M16.9257 10.5391L17.3566 8.92914C17.8599 7.04997 18.1124 6.11081 17.9232 5.29747C17.7733 4.65539 17.4363 4.07219 16.9549 3.62164C16.3457 3.05081 15.4057 2.79914 13.5266 2.29581C11.6474 1.79164 10.7074 1.53997 9.89491 1.72914C9.25282 1.87907 8.66962 2.21605 8.21908 2.69747C7.72991 3.21914 7.47491 3.98331 7.09575 5.37164L6.89241 6.12581L6.46158 7.73581C5.95741 9.61497 5.70575 10.5541 5.89491 11.3675C6.04484 12.0096 6.38183 12.5928 6.86325 13.0433C7.47241 13.6141 8.41241 13.8658 10.2916 14.37C11.9849 14.8233 12.9157 15.0725 13.6782 14.9783C13.7616 14.9683 13.8432 14.9533 13.9232 14.935C14.5651 14.7855 15.1483 14.4491 15.5991 13.9683C16.1699 13.3583 16.4216 12.4183 16.9257 10.5391Z"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M13.6769 14.9783C13.5027 15.5119 13.1966 15.9929 12.7869 16.3766C12.1778 16.9475 11.2378 17.1991 9.35861 17.7025C7.47944 18.2058 6.53944 18.4583 5.72694 18.2683C5.08495 18.1186 4.50176 17.7819 4.05111 17.3008C3.48028 16.6916 3.22778 15.7516 2.72444 13.8725L2.29361 12.2625C1.78944 10.3833 1.53778 9.44329 1.72694 8.63079C1.87687 7.9887 2.21386 7.4055 2.69528 6.95495C3.30444 6.38412 4.24444 6.13246 6.12361 5.62829C6.47861 5.53329 6.80111 5.44662 7.09444 5.37079"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M9.81156 8.33325L13.8366 9.41158M9.16406 10.7483L11.5791 11.3949"
                        stroke={notes ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  <div>
                    <div
                      className={`notesFontSize ${
                        notes ? "noteTemplateFont" : ""
                      }`}
                      onClick={() => setNotes(!notes)}
                    >
                      Notes
                    </div>
                  </div>
                </div>
                <div className="ms-2">
                  <div
                    onClick={() => {
                      setTemplates(true);
                      setTemplateModal(true);
                    }}
                    className="close-template"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.625 2.5C4.7962 2.5 4.00134 2.82924 3.41529 3.41529C2.82924 4.00134 2.5 4.7962 2.5 5.625V13.125C2.5 13.9538 2.82924 14.7487 3.41529 15.3347C4.00134 15.9208 4.7962 16.25 5.625 16.25H6.25V15H5.625C5.12772 15 4.65081 14.8025 4.29917 14.4508C3.94754 14.0992 3.75 13.6223 3.75 13.125V5.625C3.75 5.12772 3.94754 4.65081 4.29917 4.29917C4.65081 3.94754 5.12772 3.75 5.625 3.75H13.125C13.6223 3.75 14.0992 3.94754 14.4508 4.29917C14.8025 4.65081 15 5.12772 15 5.625V8.75H16.25V5.625C16.25 4.7962 15.9208 4.00134 15.3347 3.41529C14.7487 2.82924 13.9538 2.5 13.125 2.5H5.625ZM9.375 10C8.87772 10 8.40081 10.1975 8.04917 10.5492C7.69754 10.9008 7.5 11.3777 7.5 11.875V16.875C7.5 17.3723 7.69754 17.8492 8.04917 18.2008C8.40081 18.5525 8.87772 18.75 9.375 18.75H16.875C17.3723 18.75 17.8492 18.5525 18.2008 18.2008C18.5525 17.8492 18.75 17.3723 18.75 16.875V11.875C18.75 11.3777 18.5525 10.9008 18.2008 10.5492C17.8492 10.1975 17.3723 10 16.875 10H9.375ZM8.75 11.875C8.75 11.7092 8.81585 11.5503 8.93306 11.4331C9.05027 11.3158 9.20924 11.25 9.375 11.25H16.875C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875V12.48L13.125 14.91L8.75 12.48V11.875ZM8.75 13.9087L12.8212 16.1713C12.9142 16.2229 13.0187 16.25 13.125 16.25C13.2313 16.25 13.3358 16.2229 13.4288 16.1713L17.5 13.9087V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H9.375C9.20924 17.5 9.05027 17.4342 8.93306 17.3169C8.81585 17.1997 8.75 17.0408 8.75 16.875V13.9087ZM6.25 6.25C6.08424 6.25 5.92527 6.31585 5.80806 6.43306C5.69085 6.55027 5.625 6.70924 5.625 6.875C5.625 7.04076 5.69085 7.19973 5.80806 7.31694C5.92527 7.43415 6.08424 7.5 6.25 7.5H12.5C12.6658 7.5 12.8247 7.43415 12.9419 7.31694C13.0592 7.19973 13.125 7.04076 13.125 6.875C13.125 6.70924 13.0592 6.55027 12.9419 6.43306C12.8247 6.31585 12.6658 6.25 12.5 6.25H6.25Z"
                        fill={templateModal ? "#007EC3" : "#666E7D"}
                      />
                    </svg>
                  </div>
                  <div>
                    <div
                      className={`notesFontSize ${
                        templateModal ? "noteTemplateFont" : ""
                      }`}
                      onClick={() => {
                        setTemplates(true);
                        setTemplateModal(true);
                      }}
                    >
                      Templates
                    </div>
                    {templateModal && (
                      <Modall
                        isOpen={templates}
                        onClose={handleClose}
                        title={modalHead}
                        size={modalSize}
                      >
                        <TemplateList
                          component="ticket"
                          handleClose={handleClose}
                          modalTitle={modalTitle}
                          setModalSize={setModalSize}
                          selectedConversation={props.data}
                        />
                      </Modall>
                    )}
                  </div>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 32 32"
                    fill="none"
                    className="mb-2 mr-1 ml-2"
                    style={{
                      cursor:
                        extractTextFromHTML(currentMessage).trim() !== ""
                          ? "pointer"
                          : "not-allowed",
                    }}
                    onClick={(e: any) => {
                      if (extractTextFromHTML(currentMessage).trim() !== "") {
                        handleEnterKeyPress(e, "sendButton");
                      }
                    }}
                  >
                    <path
                      d="M16.0013 0.166748C18.0806 0.166748 20.1395 0.576289 22.0605 1.37199C23.9814 2.16769 25.7269 3.33396 27.1972 4.80422C28.6674 6.27448 29.8337 8.01994 30.6294 9.94093C31.4251 11.8619 31.8346 13.9208 31.8346 16.0001C31.8346 20.1993 30.1665 24.2266 27.1972 27.1959C24.2278 30.1653 20.2006 31.8334 16.0013 31.8334C13.922 31.8334 11.8631 31.4239 9.94215 30.6282C8.02116 29.8325 6.27571 28.6662 4.80544 27.1959C1.83612 24.2266 0.167969 20.1993 0.167969 16.0001C0.167969 11.8008 1.83612 7.77355 4.80544 4.80422C7.77477 1.8349 11.802 0.166748 16.0013 0.166748ZM10.0165 9.35711C9.85155 9.28634 9.66797 9.40735 9.66797 9.58687V14.277C9.66797 14.4023 9.76076 14.5083 9.885 14.5248L19.1104 15.7523C19.3998 15.7908 19.3998 16.2094 19.1104 16.2479L9.885 17.4754C9.76076 17.4919 9.66797 17.5979 9.66797 17.7232V22.4133C9.66797 22.5928 9.85155 22.7138 10.0165 22.643L24.9658 16.2298C25.1677 16.1432 25.1677 15.857 24.9658 15.7703L10.0165 9.35711Z"
                      fill={
                        extractTextFromHTML(currentMessage).trim() !== ""
                          ? "#458d80"
                          : "#ccc"
                      }
                    />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={previewModalOpen}
        toggle={() => setPreviewModalOpen(!previewModalOpen)}
      >
        <ModalHeader toggle={() => setPreviewModalOpen(!previewModalOpen)}>
          File Preview
        </ModalHeader>
        <ModalBody>
          <div>
            {innerMediatype === "image" && (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={previewUrl || ""}
                  alt="Preview"
                  style={{ width: "250px", height: "250px" }}
                />
              </div>
            )}
            {innerMediatype === "video" && (
              <div className="d-flex justify-content-center align-items-center">
                <video
                  src={previewUrl || ""}
                  controls
                  style={{ width: "250px", height: "250px" }}
                />
              </div>
            )}
            {innerMediatype === "doc" && (
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {previewUrl && (
                  <FilePlaceholder
                    mediaType={"document"}
                    fileName={files[0]?.imgName || ""}
                    url={previewUrl || ""}
                    size="large"
                  />
                )}
              </div>
            )}
          </div>
          <input
            className="captionInput"
            type="text"
            placeholder="Enter Caption"
            value={previewInput}
            onChange={(e) => setPreviewInput(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="send-btn"
            onClick={(e) => {
              handleSendMediaMessage();
              setPreviewModalOpen(false);
            }}
          >
            Send
          </Button>{" "}
          <Button
            className="cancel-btn"
            onClick={(e) => setPreviewModalOpen(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SendTicket;
