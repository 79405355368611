import axios from "axios";

export const SERVER_URL = window.location.hostname === "app.bizmagnets.ai" ? "https://api.bizmagnets.ai"
    : "https://biz.bizmagnets.ai"
    //  : "http://183.83.189.151:9912"
const baseURL = SERVER_URL + "/api"

const AxiosInstance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    }
});


AxiosInstance.interceptors.request.use(
    config => {
        const apiToken = localStorage.getItem('apiToken');
        const tokenData = apiToken ? JSON.parse(apiToken) : null;
        if (tokenData) {
            config.headers['Authorization'] = 'Bearer ' + tokenData.token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

export default AxiosInstance;
