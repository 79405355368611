import React, { useState, useRef, useEffect } from "react";
import * as yup from 'yup';
import { Formik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import FlagInput from "../../common/flagInput";
import { toast } from "../../common/alert";
import { IBillingData, updateBillingDetail, getStates, getCities } from "../../services/SubscriptionService";
import Select from 'react-select';
import styles from "./SubscriptionPage.module.scss";
import { Data } from "emoji-mart";

interface BillingInformationProps {
    onCancel: () => void;
    refetch: () => void;
    data: IBillingData;
    saveBillingDetails: () => void;
    plan: any;
}

const BillingInformation: React.FC<BillingInformationProps> = ({
    onCancel,
    refetch,
    data, saveBillingDetails, plan
}) => {
    const [code, setCode] = useState<string>('+91');
    const businessId = useSelector((state: any) => state.cartreducer.business?.business?.id);
    const flagData = useSelector((state: any) => state.cartreducer.flagData);

    const schema = yup.object().shape({
        name: yup.string().required('Billing Name is required'),
        address: yup.string().required('Billing Address is required'),
        country: yup.string().required('Country is required'),
        zip: yup
            .string()
            //.required('Zip code is required')
            .matches(/^\d{6}$/, 'Invalid Zip code'),
        // city: yup.string().required('City is required'),
        // state: yup.string().required('State is required'),
        phoneNumber: yup
            .string()
            .required('Mobile Number is required')
            .matches(/^\d{10}$/, 'Invalid Mobile Number'),
        email: yup
            .string()
            .required('Email is required')
            .email('Invalid Email'),
        //taxID: yup.string().required('Tax ID is required'),
    });

    const saveFormHandler = async (formData: any): Promise<void> => {
        try {
            await updateBillingDetail(businessId, {
                id: data.id,
                countryCode: code,
                ...formData
            });
            toast("success", "Billing Information saved");
            onCancel();
            saveBillingDetails();
            refetch();
        } catch (error) {
            toast("error", "Failed to save Billing Information");
        }
    }

    const [statesList, setStateList] = useState([])
    const [citiesList, setCitiesList] = useState([])

    const changeCountry = async (selectedCountry: any) => {
        const id = selectedCountry?.value;
        if (id) {
            const response = await getStates(id)
            setStateList(response)
        }
    }
    const changeState = async (selectedState: any) => {
        const id = selectedState?.value;
        if (id) {
            const response = await getCities(id)
            setCitiesList(response)
        }
    }
    const changeCity = async (selectedCity: any, setFieldValue: any) => {
        const id = selectedCity?.value;
        const val = selectedCity?.label;
        if (id) {
            setFieldValue('zip', '')
            setFieldValue('city', val)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (data) {
                // Find the country ID from the flagData based on the data.country
                setCode(data.countryCode);
                const country = flagData.find((item: any) => item.name === data.country);
                if (country) {
                    const countryId = country.id;
                    const states = await getStates(countryId);
                    setStateList(states);

                    // Find the state ID from the states based on data.state
                    const state = states.find((item: any) => item.name === data.state);
                    if (state) {
                        const stateId = state.id;
                        const cities = await getCities(stateId);
                        setCitiesList(cities);
                    }
                }
            }
        };

        fetchData().catch(error => {
            console.error('Error fetching data:', error);
            // Handle error if needed
        });
    }, [data, flagData]);

    const customStyles = (isDisabled: boolean) => ({
        singleValue: (provided: any) => ({
            ...provided,
            color: isDisabled ? 'black' : '',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            fontWeight: 'normal',
        }),
    });


    return (
        <Container>
            <Formik
                validationSchema={schema}
                onSubmit={saveFormHandler}
                initialValues={{
                    name: data.name || "",
                    address: data.address || "",
                    country: data.country || "",
                    zip: data.zip || "",
                    city: data.city || "",
                    state: data.state || "",
                    phoneNumber: data.phoneNumber || "",
                    email: data.email || "",
                    taxID: data.taxID || ""
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                    const isDisabled = plan?.code ? data?.country ? true : false : false;
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-2">
                                <Form.Label>Billing Name <span className="required"></span></Form.Label>
                                <Form.Control
                                    placeholder="Enter billing name"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={(touched.name && errors.name) ? true : false}
                                />
                                {(touched.name && errors.name) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.name}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Billing Address <span className="required"></span></Form.Label>
                                <Form.Control
                                    placeholder="Enter billing address"
                                    name="address"
                                    onChange={handleChange}
                                    value={values.address}
                                    isValid={touched.address && !errors.address}
                                    isInvalid={(touched.address && errors.address) ? true : false}
                                />
                                {(touched.address && errors.address) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.address}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Row className="mb-2">
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Country
                                            <span className="required"></span>
                                        </Form.Label>
                                        {/* <Form.Control
                                            placeholder="Enter country"
                                            name="country"
                                            onChange={handleChange}
                                            value={values.country}
                                            isValid={touched.country && !errors.country}
                                            isInvalid={(touched.country && errors.country) ? true : false}
                                        /> */}
                                        <Select
                                            name="country"
                                            options={
                                                flagData &&
                                                (flagData?.map((flag: any) => ({
                                                    value: flag.id,
                                                    label: flag.name,
                                                })) as [])
                                            }
                                            value={values?.country ?{ label: values?.country, value: values?.country }:''}
                                            isDisabled={isDisabled}
                                            // isClearable={true}
                                            // className={`industry ${values.role.type === "MEMBER" ? 'disabled-data':''}`}
                                            // value={values?.team ? {label:values?.team.name,value:values?.team} : null}
                                            // onChange={(selectedOption: any) => {
                                            //     setFieldValue("team", selectedOption ? selectedOption.value : null);
                                            // }}
                                            onChange={(selectCountry: any) => {
                                                changeCountry(selectCountry);
                                                const val = selectCountry?.label;
                                                setFieldValue('country', val);
                                                setFieldValue('state', '');
                                                setFieldValue('city', '');
                                                setFieldValue('zip', '');
                                            }}
                                            placeholder='Select Country'
                                            styles={customStyles(isDisabled)}
                                        />
                                        {(touched.country && errors.country) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.country}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>State
                                            {/* <span className="required"></span> */}
                                        </Form.Label>
                                        {/* <Form.Control
                                            placeholder="Enter state"
                                            name="state"
                                            onChange={handleChange}
                                            value={values.state}
                                            isValid={touched.state && !errors.state}
                                            isInvalid={(touched.state && errors.state) ? true : false}
                                        /> */}
                                        <Select
                                            name="state"
                                            options={
                                                statesList &&
                                                (statesList?.map((state: any) => ({
                                                    value: state.id,
                                                    label: state.name,
                                                })) as [])
                                            }
                                            value={values?.state ? { label: values?.state, value: values?.state } : ''}

                                            // isClearable={true}
                                            // className={`industry ${values.role.type === "MEMBER" ? 'disabled-data':''}`}
                                            // value={values?.team ? {label:values?.team.name,value:values?.team} : null}
                                            // onChange={(selectedOption: any) => {
                                            //     setFieldValue("team", selectedOption ? selectedOption.value : null);
                                            // }}
                                            onChange={(selectedState: any) => {
                                                changeState(selectedState);
                                                const val = selectedState?.label;
                                                setFieldValue('state', val);
                                                setFieldValue('city', '');
                                                setFieldValue('zip', '');
                                            }
                                            }
                                            placeholder='Select State'
                                        />
                                        {/* {(touched.state && errors.state) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.state}
                                            </div>
                                        ) : null} */}
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="mb-2">
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>City
                                            {/* <span className="required"></span> */}
                                        </Form.Label>
                                        {/* <Form.Control
                                            placeholder="Enter city"
                                            name="city"
                                            onChange={handleChange}
                                            value={values.city}
                                            isValid={touched.city && !errors.city}
                                            isInvalid={(touched.city && errors.city) ? true : false}
                                        /> */}
                                        <Select
                                            name="city"
                                            options={
                                                citiesList &&
                                                (citiesList?.map((city: any) => ({
                                                    value: city.id,
                                                    label: city.name,
                                                })) as [])
                                            }
                                            value={values?.city ? { label: values?.city, value: values?.city } : ''}
                                            // isClearable={true}
                                            // className={`industry ${values.role.type === "MEMBER" ? 'disabled-data':''}`}
                                            // value={values?.team ? {label:values?.team.name,value:values?.team} : null}
                                            // onChange={(selectedOption: any) => {
                                            //     setFieldValue("team", selectedOption ? selectedOption.value : null);
                                            // }}
                                            onChange={(selectedCity: any) => changeCity(selectedCity, setFieldValue)}
                                            placeholder='Select City'
                                        />
                                        {/* {(touched.city && errors.city) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.city}
                                            </div>
                                        ) : null} */}
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Zip code
                                            {/* <span className="required"></span> */}
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Enter zip code"
                                            name="zip"
                                            onChange={handleChange}
                                            value={values.zip}
                                            isValid={touched.zip && !errors.zip}
                                        // isInvalid={(touched.zip && errors.zip) ? true : false}
                                        />
                                        {/* {(touched.zip && errors.zip) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.zip}
                                            </div>
                                        ) : null} */}
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="mb-2">
                                <Col xs={6}>
                                    <Form.Group>
                                        <div className="form-group mobile-login-flag">
                                            <Form.Label>Mobile Number <span className="required"></span></Form.Label>
                                            <div className="input-group">
                                                <div className="input-group-prepend ms-2">
                                                    <FlagInput countryCode={`${code}`} codeChange={setCode} />
                                                </div>
                                                <Form.Control
                                                    placeholder="Enter mobile number"
                                                    name="phoneNumber"
                                                    className={`${styles.billingPhoneNum} ${styles.smallplaceholder}`}
                                                    onChange={handleChange}
                                                    maxLength={code === '91' ? 10 : 16}
                                                    value={values.phoneNumber}
                                                    isValid={touched.phoneNumber && !errors.phoneNumber}
                                                    isInvalid={(touched.phoneNumber && errors.phoneNumber) ? true : false}
                                                />
                                            </div>
                                        </div>
                                        {(touched.phoneNumber && errors.phoneNumber) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.phoneNumber}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Email <span className="required"></span></Form.Label>
                                        <Form.Control
                                            placeholder="Enter email address"
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={(touched.email && errors.email) ? true : false}
                                        />
                                        {(touched.email && errors.email) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.email}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {values.country === 'India' &&
                                <Row className="mb-2">
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Tax ID
                                                {/* <span className="required"></span> */}
                                            </Form.Label>
                                            <Form.Select disabled>
                                                <option>IN GST - India</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Form.Control
                                                placeholder="Enter tax id"
                                                name="taxID"
                                                onChange={handleChange}
                                                value={values.taxID}
                                                isValid={touched.taxID && !errors.taxID}
                                                isInvalid={(touched.taxID && errors.taxID) ? true : false}
                                            />
                                            {/* {(touched.taxID && errors.taxID) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {errors.taxID}
                                            </div>
                                        ) : null} */}
                                        </Form.Group>
                                    </Col>
                                </Row>}
                            <div className="float-right mt-2">
                                <Button
                                    className="sendButton"
                                    type="submit"
                                >
                                    Save
                                </Button>
                                <Button
                                    color="primary"
                                    type="button"
                                    className="cancelButton ml-2"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    );
};

export default BillingInformation;