import { useEffect, useRef, useState } from 'react';
import { Form, Offcanvas, Button, Spinner, Alert, FormGroup } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';

import axios from '../../../../utils/axios';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { assignConversationIcon } from '../../../icons';
import { useAppSelector } from '../../../hooks';
import SelectBot from '../../bots/selectBotInput';
import { SOMETHING_WENT_WRONG } from '../../../../constants/errorMessage';
import ReactSelect from 'react-select';
import { Value } from 'sass';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface FormData {
    conversation: {
        type: 'member' | 'bot' | 'contact_owner' |'team';
        id: number;
        
    }
};
export const SelectTeam: React.FC<any> = props => {
    const { name } = props
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [selectedValue, setSelectedValue] = useState<any | null | any>(null);
    console.log("ss",props);
    
    const [selectedUid, setSelectedUid] = useState<string | undefined>(props.value);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');
    const [check, setCheck] = useState(true);
    const [count, setCount] = useState(0);
    useEffect(() => {
        // Find the last selected option and map it to the structure expected by ReactSelect
        if (props.memberUids?.length > 0) {
            const lastOption = props.memberUids[props.memberUids.length - 1];
            // Assuming you have a way to match the uid to a label, for this example we'll use lastOption.uid directly
            // You might need to fetch or derive the correct label from somewhere if it's different
            if (query.data?.dataDTO) {
                const selected = query.data.dataDTO.find((member: any) => member.uid === lastOption.uid);

                const mappedLastSelectedOption = {
                    label: selected.name, // You might need to adjust this to get the actual label
                    value: lastOption.uid, // Assuming id here matches the structure you want for `value`
                };
                setSelectedValue(mappedLastSelectedOption);
            }
        } else {
            setSelectedValue(null);
        }
    }, [props.memberUids]);

    const handleChange = (selectedOption: any | null | any) => {
        setSelectedValue(selectedOption);
        if (selectedOption) {
            // Adjust this according to how you want to handle the selection
            if (props.setUid) {
                props.setUid(selectedOption.value);
            }
            else {
                props.setValue(selectedOption.id);
            }
        }
    };
    
    const teamquery = useQuery({
        queryKey: ['team'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/team/getAll', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    // status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        },
        enabled: true
    });

const teamMembersquery = useQuery({
    queryKey: ['teamMembers'],
    queryFn: async () => {
        try {
            if (!businessUid) {
                return new Promise(() => { });
            }
            const response = await axios.get('/team/2b0a65e2-b0ec-3494-829a-65dcb8b1d7c6')
              
                
            return response.data;

        } catch (error) {
            console.error(error);
            return [];
        }
    },
    enabled: true
});

    const query = useQuery({
        queryKey: ['members'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        },
        enabled: true
    });

    useEffect(() => {
        if (props.value) {
            const selectedMember = teamquery.data?.data.find((member: any) => member.uid === props.value);
            if (selectedMember) {
                setSelectedOption({
                    id: selectedMember.id,
                    label: selectedMember.name,
                    value: selectedMember.uid
                });
            }
        }
    }, [props.value, teamquery.data?.data]);
    //console.log(query.data?.dataDTO.find((member: any) => member.uid === props.value)?.name);

    useEffect(() => {
        if (check && props.memberUids) {
            const select = props?.memberUids[props?.memberUids?.length - 1]?.uid;
            if (select && teamquery.data?.data) {
                const selectedMember = teamquery.data.data.find((member: any) => member.uid === select);
                setCheck(false)
                if (selectedMember) {
                    setSelectedValue({
                        label: selectedMember.name,
                        value: selectedMember.uid,
                        id: selectedMember.id
                    });
                }
            }
        }
    });

    useEffect(() => {
        // Use conversation.id to find and set the selected member
        if (props.conversation && teamquery.data?.data) {
            const selectedMember = teamquery.data.data.find((member: any) => member.id === props.conversation);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid,
                    id: selectedMember.id
                });
            }
        }
    }, [props.conversation, teamquery.data?.data]);

    useEffect(() => {
        if (selectedValue && teamquery.data?.data) {
            const selectedMember = teamquery.data?.data.find((member: any) => member.uid === selectedValue.value);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid, // Assuming this is the unique identifier
                    id: selectedMember.id, // Use this if needed, but not required for ReactSelect
                });
            }
        }
    }, [selectedUid, teamquery.data?.data]);

    if (query.isLoading) {
        return <Spinner />
    }
    if (query.error) {
        return <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    }
    return (
        <Form.Group>
            <ReactSelect
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menu: base => ({ ...base, width: 410, position: "relative" })
                }}
                menuPlacement="auto"
                options={teamquery.data?.data?.map((member: any) => ({
                    label: member.name,
                    value: member.uid,
                    id: member.id
                }))}
                value={selectedValue}
                onChange={handleChange}
                placeholder="Select Team..."
                maxMenuHeight={120}
            />
        </Form.Group>
    )
};
export const SelectTeamMembers: React.FC<any> = props => {
    console.log("sd",props.selectedmem);
    const { name,member,selectedmem } = props

    const previousMemberRef = useRef<string | null>(null);

    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [selectedValue, setSelectedValue] = useState<any | null | any>(null);
        console.log("va",selectedValue);

    const [selectedUid, setSelectedUid] = useState<string | undefined>(props.value);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');
    const [check, setCheck] = useState(true);
    const [count, setCount] = useState(0);
    useEffect(() => {
        // Find the last selected option and map it to the structure expected by ReactSelect
        if (props.memberUids?.length > 0) {
            const lastOption = props.memberUids[props.memberUids.length - 1];
            // Assuming you have a way to match the uid to a label, for this example we'll use lastOption.uid directly
            // You might need to fetch or derive the correct label from somewhere if it's different
            if (teamMembersquery.data?.members) {
                const selected = teamMembersquery.data.members.find((member: any) => member.uid === lastOption.uid);

                const mappedLastSelectedOption = {
                    label: selected.name, // You might need to adjust this to get the actual label
                    value: lastOption.uid, // Assuming id here matches the structure you want for `value`
                };
                setSelectedValue(mappedLastSelectedOption);
            }
        } else {
            setSelectedValue(null);
        }
    }, [props.memberUids]);

   
    const handleChange = (selectedOption: any | null | any) => {
        setSelectedValue(selectedOption);
        if (selectedOption) {
            // Adjust this according to how you want to handle the selection
            if (props.setUid) {
                props.setUid(selectedOption.value);
            }
            else {
                props.setValue(selectedOption.id);
            }
        }
    };
    
    const teamquery = useQuery({
        queryKey: ['team'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/team/getAll', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    // status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        },
        enabled: true
    });

const teamUid=teamquery.data?.data?.find((member:any)=>member.id===props.member)

const teamMembersquery = useQuery({
    queryKey: ['teamMembers'],
    queryFn: async () => {
        try {
            if (!businessUid) {
                return new Promise(() => { });
            }
            const response = await axios.get(`/team/${teamUid?.uid}`)
              
                
            return response.data;

        } catch (error) {
            console.error(error);
            return [];
        }
    },
    enabled: true
});
console.log("tea",teamMembersquery.data.members.find((data:any)=>data.id===selectedmem.conversation.memberId));
console.log("id",selectedmem.conversation.memberId)

useEffect(() => {
    if (previousMemberRef.current !== member) {
        teamMembersquery.refetch();
        previousMemberRef.current = member;
    }
}, [member, teamMembersquery]);

    const query = useQuery({
        queryKey: ['members'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        },
        enabled: true
    });

    useEffect(() => {
        if (props.value) {
            console.log("true useeffect");
            
            const selectedMember = teamMembersquery.data?.members.find((member: any) => member.uid === props.value);
            if (selectedMember) {
                console.log("true useeffect1");

                setSelectedOption({
                    id: selectedMember.id,
                    label: selectedMember.name,
                    value: selectedMember.uid
                });
            }
        }
    }, [props.value, teamMembersquery.data?.members]);
    //console.log(query.data?.dataDTO.find((member: any) => member.uid === props.value)?.name);

    useEffect(() => {
        if (check && props.memberUids) {
            console.log("true useeffect2");

            const select = props?.memberUids[props?.memberUids?.length - 1]?.uid;
            if (select && teamMembersquery.data?.members) {
                console.log("true useeffect2");

                const selectedMember = teamMembersquery.data?.members.find((member: any) => member.uid === select);
                setCheck(false)
                if (selectedMember) {
                    setSelectedValue({
                        label: selectedMember.name,
                        value: selectedMember.uid,
                        id: selectedMember.id
                    });
                }
            }
        }
    });

    useEffect(() => {
        // Use conversation.id to find and set the selected member
        if (props.conversation && teamMembersquery.data?.data) {
            console.log("true useeffect3");

            const selectedMember = teamMembersquery.data?.members.find((member: any) => member.id === props.conversation);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid,
                    id: selectedMember.id
                });
            }
        }
    }, [props.conversation, teamMembersquery.data?.members]);

    useEffect(() => {
        if (selectedValue && teamMembersquery.data?.members) {
            console.log("true useeffect23");

            const selectedMember = teamMembersquery.data?.members.find((member: any) => member.uid === selectedValue.value);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid, // Assuming this is the unique identifier
                    id: selectedMember.id, // Use this if needed, but not required for ReactSelect
                });
            }
        }
    }, [selectedUid, teamMembersquery.data?.members]);
    useEffect(() => {
        if (selectedmem?.conversation?.memberId) {
                const selected = teamMembersquery.data?.members?.find((data:any)=>data.id===selectedmem.conversation.memberId);
                const mappedLastSelectedOption = {
                    label: selected?.name, // You might need to adjust this to get the actual label
                    value: selected?.uid, // Assuming id here matches the structure you want for `value`
                };
                setSelectedValue(mappedLastSelectedOption);
            
        } else {
            setSelectedValue(null);
        }
    }, [selectedmem]);

    if (query.isLoading) {
        return <Spinner />
    }
    if (query.error) {
        return <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    }
    return (
        <Form.Group>
            <ReactSelect
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menu: base => ({ ...base, width: 410, position: "relative" })
                }}
                menuPlacement="auto"
                options={teamMembersquery.data?.members?.map((member: any) => ({
                    label: member.name,
                    value: member.uid,
                    id: member.id
                }))}
                value={selectedValue}
                onChange={handleChange}
                placeholder="Select Team..."
                maxMenuHeight={120}
            />
        </Form.Group>
    )
};

export const SelectMember: React.FC<any> = props => {
    const { name } = props
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [selectedValue, setSelectedValue] = useState<any | null | any>(null);
    const [selectedUid, setSelectedUid] = useState<string | undefined>(props.value);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');
    const [check, setCheck] = useState(true);
    const [count, setCount] = useState(0);
    useEffect(() => {
        // Find the last selected option and map it to the structure expected by ReactSelect
        if (props.memberUids?.length > 0) {
            const lastOption = props.memberUids[props.memberUids.length - 1];
            // Assuming you have a way to match the uid to a label, for this example we'll use lastOption.uid directly
            // You might need to fetch or derive the correct label from somewhere if it's different
            if (query.data?.dataDTO) {
                const selected = query.data.dataDTO.find((member: any) => member.uid === lastOption.uid);

                const mappedLastSelectedOption = {
                    label: selected?.name, // You might need to adjust this to get the actual label
                    value: lastOption?.uid, // Assuming id here matches the structure you want for `value`
                };
                setSelectedValue(mappedLastSelectedOption);
            }
        } else {
            setSelectedValue(null);
        }
    }, [props.memberUids]);

    const handleChange = (selectedOption: any | null | any) => {
        setSelectedValue(selectedOption);
        if (selectedOption) {
            // Adjust this according to how you want to handle the selection
            if (props.setUid) {
                props.setUid(selectedOption.value);
            }
            else {
                props.setValue(selectedOption.id);
            }
        }
    };


    const query = useQuery({
        queryKey: ['members'],
        queryFn: async () => {
            try {
                if (!businessUid) {
                    return new Promise(() => { });
                }
                const response = await axios.post('/member/getMembers', {
                    businessUid,
                    page: 0,
                    limit: 1000,
                    status: ["ACTIVE"]
                });
                return response.data;

            } catch (error) {
                console.error(error);
                return [];
            }
        },
        enabled: true
    });

    useEffect(() => {
        if (props.value) {
            const selectedMember = query.data?.dataDTO.find((member: any) => member.uid === props.value);
            if (selectedMember) {
                setSelectedOption({
                    id: selectedMember.id,
                    label: selectedMember.name,
                    value: selectedMember.uid
                });
            }
        }
    }, [props.value, query.data?.dataDTO]);
    //console.log(query.data?.dataDTO.find((member: any) => member.uid === props.value)?.name);

    useEffect(() => {
        if (check && props.memberUids) {
            const select = props?.memberUids[props?.memberUids?.length - 1]?.uid;
            if (select && query.data?.dataDTO) {
                const selectedMember = query.data.dataDTO.find((member: any) => member.uid === select);
                setCheck(false)
                if (selectedMember) {
                    setSelectedValue({
                        label: selectedMember.name,
                        value: selectedMember.uid,
                        id: selectedMember.id
                    });
                }
            }
        }
    });

    useEffect(() => {
        // Use conversation.id to find and set the selected member
        if (props.conversation && query.data?.dataDTO) {
            const selectedMember = query.data.dataDTO.find((member: any) => member.id === props.conversation);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid,
                    id: selectedMember.id
                });
            }
        }
    }, [props.conversation, query.data?.dataDTO]);

    useEffect(() => {
        if (selectedValue && query.data?.dataDTO) {
            const selectedMember = query.data.dataDTO.find((member: any) => member.uid === selectedValue.value);
            if (selectedMember) {
                setSelectedValue({
                    label: selectedMember.name,
                    value: selectedMember.uid, // Assuming this is the unique identifier
                    id: selectedMember.id, // Use this if needed, but not required for ReactSelect
                });
            }
        }
    }, [selectedUid, query.data?.dataDTO]);

    if (query.isLoading) {
        return <Spinner />
    }
    if (query.error) {
        return <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    }
    return (
        <Form.Group>
            <ReactSelect
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    menu: base => ({ ...base, width: 410, position: "relative" })
                }}
                menuPlacement="auto"
                options={query.data.dataDTO?.map((member: any) => ({
                    label: member.name,
                    value: member.uid,
                    id: member.id
                }))}
                value={selectedValue}
                onChange={handleChange}
                placeholder="Select member..."
                maxMenuHeight={120}
            />
        </Form.Group>
    )
};

SelectMember.defaultProps = {
    field: "id"
}

function AssignConversationEditor(props: IEditorProps) {

    const [formData, setFormData] = useState<FormData>({
        conversation: {
            type: 'contact_owner',
            id: -1,
           
        }
    });
    console.log("da",formData);
    
    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/assign_conversation',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        conversation: yup.object().shape({
            type: yup
                .string()
                .required('Assignee type is required')
                .oneOf(['member', 'bot', 'contact_owner','team'], 'Assignee type is not valid'),
            id: yup
                .number()
                .when(['type'], {
                    is: (type: string, roundRobin: boolean) => type !== 'contact_owner',
                    then: schema => schema.required('Assignee is required'),
                    otherwise: schema => schema.notRequired(),
                  }),
                //   memberId: yup
                //   .number()
                //   .when(['type', 'roundRobin'], {
                //       is: (type: string, roundRobin: boolean) => type === 'team' && !roundRobin,
                //       then: schema => schema.required('Member is required'),
                //       otherwise: schema => schema.notRequired(),
                //   }),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Assign Conversation' icon={<img alt='' src={assignConversationIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>Type</Form.Label>
                                <Form.Select
                                    name='conversation.type'
                                    value={values.conversation?.type}
                                    isInvalid={(touched.conversation?.type && errors.conversation?.type) ? true : false}
                                    onChange={e => {
                                        if (e.target.value !== 'contact_owner') {
                                            setFieldValue('conversation.id', '');
                                        } else {
                                            setFieldValue('conversation.id', -1);
                                        }
                                        handleChange(e);
                                    }}
                                    isValid={touched.conversation?.type && !errors.conversation?.type}
                                >
                                    <option value="contact_owner">Contact Owner</option>
                                    <option value="bot">Bot</option>
                                    <option value="member">Member</option>
                                    <option value="team">Team</option>

                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.conversation?.type}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {values?.conversation?.type !== 'contact_owner' && values?.conversation?.type !== 'team' && (
                                <Form.Group className='mb-3'>
                                    <Form.Label>{values.conversation?.type === 'bot' ? 'Select bot' : values?.conversation?.type==="member" ? 'Select Member':''}</Form.Label>
                                    {values?.conversation?.type === 'bot' ? (
                                        <SelectBot
                                            value={values.conversation.id || ''}
                                            setValue={(_: string, botId: number) => {
                                                setFieldValue('conversation.id', botId);
                                            }}
                                            isInvalid={(touched.conversation?.id && errors.conversation?.id) ? true : false}
                                            isValid={touched.conversation?.id && !errors.conversation?.id}
                                        />
                                    ) :
                                        (values?.conversation?.type==='member') && (
                                            <SelectMember
                                                setValue={(memberUid: string) => {
                                                    setFieldValue('conversation.id', memberUid);
                                                }}
                                                isInvalid={(touched.conversation?.id && errors.conversation?.id) ? true : false}
                                                isValid={touched.conversation?.id && !errors.conversation?.id}
                                                conversation={values.conversation.id}
                                            />
                                        )}
                                    {(touched.conversation?.id && errors.conversation?.id) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.conversation?.id}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            )}
                             {values.conversation.type=="team" &&(
                                <>
                                                <Form.Group className='mb-1'>
                                                                <Form.Label>Select Team</Form.Label>
                                 <SelectTeam
                                            setValue={(memberUid: string) => {
                                                setFieldValue('conversation.id', memberUid);
                                            }}
                                            isInvalid={(touched.conversation?.id && errors.conversation?.id) ? true : false}
                                            isValid={touched.conversation?.id && !errors.conversation?.id}
                                            conversation={values.conversation.id}
                                        />
                                         {(touched.conversation?.id && errors.conversation?.id) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.conversation?.id}
                                        </div>
                                    ) : null}
                                        </Form.Group>
                                      
                                        </>
                                    )}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default AssignConversationEditor;