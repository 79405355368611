import { ListGroup } from 'react-bootstrap';
import { Handle } from 'reactflow';
import { Position } from 'reactflow';

import Node from './node';
import delayStyle from './delay.module.css';

function Condition(props: any) {
    return (
        <div>
            <Node {...props} label='Condition' content={(
                <>
                    {props.data.conditionOperation.rules.map((rule: any, index: number) => {
                        return <div key={`${rule.variable}${index}`}>{rule.variable} {rule.operator} {rule.value}</div>
                    })}
                    <ListGroup>
                        <ListGroup.Item className={[delayStyle.endpoint, delayStyle.success].join(' ')}>
                            <div>
                                <span className={delayStyle.caption}>On Success</span>
                                {!props.readOnly ? (
                                    <Handle className={delayStyle.dot} id='condition/if' type='source' position={Position.Right} isConnectable />
                                ) : null}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className={[delayStyle.endpoint, delayStyle.failed].join(' ')}>
                            <div>
                                <span className={delayStyle.caption}>On Fail</span>
                                {!props.readOnly ? (
                                    <Handle className={delayStyle.dot} id='condition/else' type='source' position={Position.Right} isConnectable />
                                ) : null}
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </>
            )} />
        </div>
    );
}

export default Condition;